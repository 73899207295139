import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import api from '../utils/ApiClient';
import './Register.css';

function Register() {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('UT03');
  const [referralCode, setReferralCode] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showConfirmDialogAwait } = useAwaitConfirmDialog();

  // 验证输入信息并显示滑块   
  const handleRegister = async () => {
    if (!username || !password || !confirmPassword || !lastName || !firstName) {
      showConfirmDialogAwait(t('prompt'), t('fillAllFields'));
      return;
    }

    if (password !== confirmPassword) {
      showConfirmDialogAwait(t('prompt'), t('passwordMismatch'));
      return;
    }

    // 等待用户确认
    const confirmed = await showConfirmDialogAwait(
      t('confirm'),
      t('confirmRegister', {
        userType: t(`userTypeDist.${userType}`)
      })
    );

    if (!confirmed) {
      return;
    }

    try {
      const response = await api.post('/register', {
        username,
        firstName,
        lastName,
        password,
        userType,
        referralCode
      });

      if (response.status === 200) {
        showConfirmDialogAwait(t('prompt'), t('registerSuccess'));
        navigate('/login');
      }
    } catch (error: any) {
      showConfirmDialogAwait(t('prompt'), error.response.data || t('registerFailed'));
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <div className="register-header">
          <h1 className="register-title">{(t as any)('register')}</h1>
          <LanguageSwitcher />
        </div>
        <div className="input-wrapper">
          <div className="name-inputs">
            <input
              className="input name-input"
              type="text"
              placeholder={(t as any)('firstName')}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <input
              className="input name-input"
              type="text"
              placeholder={(t as any)('lastName')}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <input
            className="input"
            type="text"
            placeholder={(t as any)('username')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoCapitalize="none"
          />
          
          <input
            className="input"
            type="password"
            placeholder={(t as any)('password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <input
            className="input"
            type="password"
            placeholder={(t as any)('confirmPassword')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          
          {userType === 'UT03' && (
            <>
              <input
                className="input"
                type="text"
                placeholder={(t as any)('referralCode')}
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
              <div>填写推荐码即可获得更多试听课时</div>
            </>
          )}
          
          <div className="radio-group">
            <label style={{ marginRight: '20px' }}>
              <input
                type="radio"
                name="userType"
                value="UT02"
                checked={userType === 'UT02'}
                onChange={(e) => {
                  setUserType(e.target.value);
                  setReferralCode(''); // 切换到老师时清空推荐码
                }}
              />
              {(t as any)('teacher')}
            </label>
            <label>
              <input
                type="radio"
                name="userType"
                value="UT03"
                checked={userType === 'UT03'}
                onChange={(e) => setUserType(e.target.value)}
              />
              {(t as any)('student')}
            </label>
          </div>
          
          <button
            className="register-button"
            onClick={handleRegister}
          >
            {(t as any)('register')}
          </button>

          <button
            className="login-link"
            onClick={() => navigate('/login')}
          >
            {(t as any)('haveAccount')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Register;

