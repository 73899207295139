import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHome, FaMicrophone, FaMicrophoneSlash, FaPlay, FaVideo, FaVideoSlash } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useGoToHome } from '../hook/useCommonFunction';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setRoomInfo } from '../store/slices/roomSlice';
import { setConfirmDialog } from '../store/slices/uiSlice';
import { initialize, setAudioEnabled, setLocalStream, setVideoEnabled } from '../store/slices/webrtcSlice';
import { connect } from '../store/slices/websocketSlice';
import { isValidUserType, UserType } from '../types/user';
import api from '../utils/ApiClient';
import './ConnectionPrep.css';

const ConnectionPrep = () => {
    const { t } = useTranslation();
    const goToHome = useGoToHome();
    const location = useLocation();
    const { teacherId, roomId } = location.state || {};
    const { userId, userType } = useAppSelector(state => state.user);
    const navigate = useNavigate();
    const { status: wsStatus, error: wsError } = useAppSelector(state => state.websocket);
    const { status: webrtcStatus, localStream, isVideoEnabled, isAudioEnabled, peerConnection } = useAppSelector(state => state.webrtc);
    const dispatch = useAppDispatch();
    const [streamReady, setStreamReady] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isStarting, setIsStarting] = useState(false);
    const [peerConnectionState, setPeerConnectionState] = useState<RTCPeerConnectionState>();
    const { showConfirmDialogAwait } = useAwaitConfirmDialog();

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    // 校验
    useEffect(() => {
        if (!roomId) {
            showConfirmDialogAwait(t('prompt'), (t as any)('connectionParamError'));
            goToHome();
            return;
        }
        if (!userId || !isValidUserType(userType)) {
            showConfirmDialogAwait(t('prompt'), (t as any)('userParamError'));
            navigate('/login');
            return;
        }
    }, []);

    // Initialize media stream
    useEffect(() => {
        initStream();
    }, []);

    const initStream = async () => {
        let videoStream: MediaStream | null = null;
        let audioStream: MediaStream | null = null;
        const finalTracks: MediaStreamTrack[] = [];

        dispatch(setVideoEnabled(false));
        dispatch(setAudioEnabled(false));

        // 尝试获取视频权限
        try {
            videoStream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    facingMode: 'user',
                },
                audio: false
            });
            finalTracks.push(...videoStream.getVideoTracks());
            console.log('[Media] Video tracks obtained', finalTracks);
        } catch (error) {
            console.warn('[Media] Failed to get video stream:', error);
            // 创建一个只包含视频轨道的占位画面
            const canvas = document.createElement('canvas');
            canvas.width = 640;
            canvas.height = 480;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.fillStyle = 'black';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.fillStyle = 'white';
                ctx.font = '20px Arial';
                ctx.fillText('Camera Unavailable', 220, 240);
            }
            // 确保只获取视频轨道
            const placeholderStream = canvas.captureStream(30); // 添加帧率参数
            const videoTracks = placeholderStream.getVideoTracks();
            finalTracks.push(...videoTracks);
            console.log('[Media] Added placeholder video track', finalTracks);
        }

        // 尝试获取音频权限
        try {
            audioStream = await navigator.mediaDevices.getUserMedia({
                video: false,
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                }
            });
            finalTracks.push(...audioStream.getAudioTracks());
            console.log('[Media] Audio tracks obtained', finalTracks);
        } catch (error) {
            console.warn('[Media] Failed to get audio stream:', error);
        }

        // 检查是否至少获取到一个轨道
        if (finalTracks.length === 0) {
            throw new Error('无法获取摄像头或麦克风权限，请至少允许使用其中一个设备');
        }

        // 创建新的 MediaStream 包含所有可用的轨道
        const mediaStream = new MediaStream(finalTracks);

        // 只有在有对应类型的轨道时才设置启用状态
        const videoTracks = mediaStream.getVideoTracks();
        if (videoTracks.length > 0) {
            videoTracks.forEach(track => track.enabled = true);
            dispatch(setVideoEnabled(true));
        } else {
            dispatch(setVideoEnabled(false));
        }

        const audioTracks = mediaStream.getAudioTracks();
        if (audioTracks.length > 0) {
            audioTracks.forEach(track => track.enabled = true);
            dispatch(setAudioEnabled(true));
        } else {
            dispatch(setAudioEnabled(false));
        }

        console.log('[Media] Final stream tracks:', mediaStream.getTracks().map(t => t.kind));

        dispatch(setLocalStream(mediaStream));

        // 确保 video 元素存在并且已经加载完成
        if (videoRef.current) {
            videoRef.current.srcObject = mediaStream;
            // 添加加载事件监听
            videoRef.current.onloadedmetadata = () => {
                videoRef.current?.play().catch(e => console.warn('Video play failed:', e));
            };
        }
        setStreamReady(true);

    };

    // 使用 useEffect 监听 peerConnection 的状态变化
    useEffect(() => {
        if (!peerConnection) return;
        // 监听连接状态变化
        const handleConnectionStateChange = () => {
            console.log('Connection state changed:', peerConnection.connectionState);
            setPeerConnectionState(peerConnection.connectionState);
        };
        peerConnection.addEventListener('connectionstatechange', handleConnectionStateChange);
        // // 初始状态
        setPeerConnectionState(peerConnection.connectionState);
        // 清理函数
        return () => {
            peerConnection.removeEventListener('connectionstatechange', handleConnectionStateChange);
        };
    }, [peerConnection]);

    useEffect(() => {
        dispatch(setRoomInfo({
            roomId: roomId || '',
        }));
        // 连接信令服务器
        socketConnect();
    }, []);

    const socketConnect = () => {
        dispatch(connect({
            type: 'init-user',
            userId: userId || '',
            date: new Date().toISOString()
        }));
    }

    // Toggle video/audio
    const toggleVideo = () => {
        if (localStream) {
            localStream.getVideoTracks().forEach(track => {
                track.enabled = !track.enabled;
            });
            dispatch(setVideoEnabled(!isVideoEnabled));
        }
    };

    const toggleAudio = () => {
        if (localStream) {
            localStream.getAudioTracks().forEach(track => {
                track.enabled = !track.enabled;
            });
            dispatch(setAudioEnabled(!isAudioEnabled));
        }
    };

    // Start button handler
    const handleStart = async () => {
        if (wsStatus !== 'connected') {
            dispatch(setConfirmDialog({
                isOpen: true,
                title: (t as any)('confirm'),
                message: (t as any)('socketConnectNoReady'),
                onConfirm: () => {
                    socketConnect();
                },
                onCancel: () => {
                    goToHome();
                }
            }));
            return;
        }
        if (!streamReady) {
            dispatch(setConfirmDialog({
                isOpen: true,
                title: (t as any)('confirm'),
                message: (t as any)('mediaNoReady'),
                onConfirm: () => {
                    initStream();
                },
                onCancel: () => {
                    goToHome();
                }
            }));
            return;
        }

        setIsStarting(true);  // 开始连接时设置状态

        if (UserType.TEACHER === userType) {
            if (!await createRoom()) {
                setIsStarting(false);
                return;
            }
            // 教师端初始化 WebRTC
            if (wsStatus === 'connected' && streamReady) {
                dispatch(initialize({
                    roomId: roomId || '',
                    userId: userId || ''
                }));
            }
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isStarting) {
                setIsStarting(false);
                if (!leaveRoom()) {
                    showConfirmDialogAwait(t('prompt'), '发生异常');
                    goToHome();
                    return;
                }
            }
        }, 6000);
        return () => clearTimeout(timer); // 清理定时器
    }, [isStarting]);

    const createRoom = async () => {
        try {
            const response = await api.post('/room/create', {
                userId: userId || '',
                roomId: roomId || '',
            });
            if (response.data !== true) {
                showConfirmDialogAwait(t('prompt'), '创建房间失败');
                return false;
            }
        } catch (error: any) {
            showConfirmDialogAwait(t('prompt'), error.response.data || '创建房间失败，请稍后重试');
            return false;
        }
        return true;
    }

    const leaveRoom = async () => {
        try {
            await api.post('/room/leave', {
                roomId: roomId || '',
                userId: userId || '',
            });
        } catch (error: any) {
            showConfirmDialogAwait(t('prompt'), error.response.data || '离开房间失败，请稍后重试');
            return false;
        }
        return true;
    }

    // 进入房间
    useEffect(() => {
        if (isStarting && wsStatus === 'connected') {
            if (UserType.TEACHER === userType) {
                if (peerConnection?.connectionState !== 'new') {
                    return;
                }
                delay(2500).then(() => {
                    // navigate(`/room/${roomId}?userId=${userId}&type=${type}`);
                    navigate(`/room/${roomId}`);
                });
            }
        }
    }, [isStarting, peerConnection?.connectionState]);

    const renderConnectionStatus = () => {
        const getStatusMessage = (type: 'ws' | 'webrtc', status: string) => {
            const messages = {
                ws: {
                    connected: t('wsConnected'),
                    connecting: t('wsConnecting'),
                    disconnected: t('wsDisconnected'),
                    failed: wsError || t('wsFailed'),
                    new: t('wsNew')
                },
                webrtc: {
                    connected: t('webrtcConnected'),
                    connecting: t('webrtcConnecting'),
                    disconnected: t('webrtcDisconnected'),
                    failed: t('webrtcFailed'),
                    new: t('webrtcNew')
                }
            };
            return messages[type][status as keyof typeof messages.ws];
        };

        const getStatusIcon = (status: string) => {
            switch (status) {
                case 'connected': return <div className="success-icon">✓</div>;
                case 'failed': return <div className="error-icon">✗</div>;
                case 'disconnected': return <div className="error-icon"></div>;
                default: return <div className="loading-spinner"></div>;
            }
        };

        return { getStatusMessage, getStatusIcon };
    };

    const { getStatusMessage, getStatusIcon } = renderConnectionStatus();

    const renderStartButton = () => {
        if (isStarting) {
            return (
                <button className="start-button connecting" disabled>
                    <div className="loading-spinner"></div>
                </button>
            );
        }

        if (wsStatus === 'connected') {
            return (
                <button className="start-button" onClick={handleStart}>
                    <FaPlay />
                </button>
            );
        }

        return null;
    };

    return (
        <div className="connection-prep">
            <div className="connection-status">
                <h2>{t('roomTitle')}</h2>
                
                <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                    className="video-preview"
                    onLoadedMetadata={(e) => {
                        const video = e.target as HTMLVideoElement;
                        video.play().catch(err => console.warn('Video play failed:', err));
                    }}
                />

                <div className="controls-container">
                    <div className="control-buttons">
                        <button 
                            className={`control-button ${isVideoEnabled ? 'active' : 'inactive'}`}
                            onClick={toggleVideo}
                        >
                            {isVideoEnabled ? <FaVideo /> : <FaVideoSlash />}
                        </button>
                        <button 
                            className={`control-button ${isAudioEnabled ? 'active' : 'inactive'}`}
                            onClick={toggleAudio}
                        >
                            {isAudioEnabled ? <FaMicrophone /> : <FaMicrophoneSlash />}
                        </button>
                    </div>

                    {renderStartButton()}
                </div>

                <div className="connection-status-container">
                    <div className="connection-item">
                        {getStatusIcon(wsStatus)}
                        <p>{getStatusMessage('ws', wsStatus)}</p>
                    </div>
                    <div className="connection-item">
                        {getStatusIcon(webrtcStatus)}
                        <p>{getStatusMessage('webrtc', webrtcStatus)}</p>
                    </div>
                </div>

                {(wsStatus === 'failed' || wsStatus === 'disconnected') && (
                    <div className="action-buttons">
                        {/* <button onClick={handleRetry}><FaRedo /></button> */}
                        {/* <button onClick={() => navigate('/')}><FaHome /></button> */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConnectionPrep; 