import axios from 'axios';
import config from '../config/config';
import i18n from '../i18n/i18n';  // 直接导入已配置的 i18n 实例

const api = axios.create({
    baseURL: config.API_URL,
});

// 请求拦截器：添加 token
api.interceptors.request.use(
    async (config: any) => {
        const token = await localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        // 添加语言请求头
        const currentLang = i18n.language;
        config.headers['Accept-Language'] = currentLang === 'zh' ? 'zh-CN' : 'en-US';

        console.log('Full Request URL:', `${config.baseURL}${config.url}`);
        console.log('Full Request Headers:', config.headers);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器：处理 token 过期
api.interceptors.response.use(
    (response) => response,
    async (error: any) => {
        // 如果是取消请求导致的错误，直接抛出不需要额外处理
        if (axios.isCancel(error)) {
            throw error;
        }

        console.error('错误类型:', error.constructor.name);
        console.error('错误信息:', error.message);
        console.error('错误代码:', error.code);
        console.error('错误信息:', error.response?.data?.message);

        if (error.response) {
            // 处理其他响应错误
            // 详细的错误日志
            console.log('错误类型:', error.name);
            console.log('错误信息:', error.message);
            console.log('错误代码:', error.code);
            console.log('错误信息:', error.response);

            if (error.message === 'Request aborted') {
                console.log('请求被中断，详细配置:', {
                    method: error.config?.method,
                    url: error.config?.url,
                    baseURL: error.config?.baseURL,
                    timeout: error.config?.timeout,
                    headers: error.config?.headers
                });
            }

            console.log('error.response', error.response);
            // 如果错误没有响应，则显示服务器不可用
            if (!error.response) {
                alert(i18n.t('serverNotAvailable'));
                // 直接终止，不再向上抛出错误
                return new Promise(() => { });  // 永远处于 pending 状态
            }

            const originalRequest = error.config;
            // 如果错误状态码为401，则表示token过期
            if (error.response?.status === 401 && !originalRequest._retry) {
                console.log('Token 过期，尝试刷新');
                originalRequest._retry = true;

                const isConfirmed = window.confirm(i18n.t('loginExpired') as string);
                await localStorage.removeItem('token');
                if (isConfirmed) {
                    window.location.href = "/login";
                }
                return new Promise(() => { });  // 永远处于 pending 状态

                // 刷新token
                // try {
                //     const newToken = await handleTokenRefresh();
                //     if (newToken) {
                //         originalRequest.headers.Authorization = `Bearer ${newToken}`;
                //         return api(originalRequest);
                //     }
                // } catch (refreshError) {
                //     await localStorage.removeItem('token');
                //     await localStorage.removeItem('refreshToken');
                //     navigate("/login");
                //     return Promise.reject(refreshError);
                // }
            }
        }

        return Promise.reject(error);
    }
);

export default api; 