import { FC, useEffect, useState } from 'react';
import api from '../utils/ApiClient';
import './AppointmentTime.css';
import AppointmentTimeSlot from './AppointmentTimeSlot';

interface AppointmentTeacherTimeSlot {
    id: string;
    userId: string;
    date: string;
    startTime: string;
    endTime: string;
    status: string;
}

export interface Appointment {
    id: string;
    studentId: string;
    teacherId: string;
    date: string;
    startTime: string;
    endTime: string;
    status: string;
}

interface TimeSlotInfo {
    isBooked: boolean;
    appointments: Appointment[];
}

interface AppointmentTimeProps {
    teacherId: string;
}

const AppointmentTime: FC<AppointmentTimeProps> = ({ teacherId }) => {
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [appointmentTeacherTimeSlot, setAppointmentTeacherTimeSlot] = useState<AppointmentTeacherTimeSlot[]>([]);


    useEffect(() => {
        fetchAppointments();
    }, []);

    const fetchAppointments = async () => {
        try {
            const response = await api.get<Appointment[]>(`/appointments/student/confirmed/${teacherId}`);
            console.info("response.data", response.data);
            setAppointments(response.data);
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    useEffect(() => {
        fetchAppointmentTeacherTimeSlots();
    }, []);

    const fetchAppointmentTeacherTimeSlots = async () => {
        try {
            const response = await api.get<AppointmentTeacherTimeSlot[]>(`/appointments/teacher/${teacherId}`);
            console.info("response.data", response.data);
            setAppointmentTeacherTimeSlot(response.data);
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    const [selectedSlot, setSelectedSlot] = useState<{
        isOpen: boolean;
        dayIndex: number;
        hour: number;
    }>({
        isOpen: false,
        dayIndex: 0,
        hour: 0,
    });

    // 将时间字符串转换为小时数
    const getHourFromTimeString = (timeString: string) => {
        return parseInt(timeString.split(':')[0]);
    };

    const formatDate = (date: Date) => {
        return date.toLocaleDateString('zh-CN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'short'
        });
    };

    // 辅助函数：将日期转换为 YYYY-MM-DD 格式
    const formatDateToString = (date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // 保持从当前日期开始
    const getNextSevenDays = () => {
        const dates = [];
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        for (let i = 0; i < 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            dates.push(date);
        }
        return dates;
    };

    // 获取时间段的预约信息
    const getTimeSlotInfo = (date: Date, hour: number): TimeSlotInfo => {
        const dateString = formatDateToString(date);
        
        const slotAppointments = appointments.filter(apt => {
            // 检查日期是否匹配
            if (apt.date !== dateString) return false;
            
            // 获取预约的开始和结束小时及分钟
            const startHour = parseInt(apt.startTime.split(':')[0]);
            const endHour = parseInt(apt.endTime.split(':')[0]);
            
            // 修改判断逻辑：如果当前小时等于开始小时，就认为是已预约
            return hour === startHour;
        });

        return {
            isBooked: slotAppointments.length > 0,
            appointments: slotAppointments
        };
    };

    // 添加新的辅助函数来检查教师是否在特定时间段可用
    const isTeacherAvailable = (date: Date, hour: number): boolean => {
        const dateString = formatDateToString(date);
        return appointmentTeacherTimeSlot.some(slot => {
            if (slot.date !== dateString) return false;
            const startHour = getHourFromTimeString(slot.startTime);
            const endHour = slot.endTime === '00:00:00' ? 24 : getHourFromTimeString(slot.endTime);
            return hour === startHour && hour === (endHour - 1);
        });
    };

    // 更新 getTimeLineClass 函数
    const getTimeLineClass = (date: Date, hour: number) => {
        const { isBooked } = getTimeSlotInfo(date, hour);
        const available = isTeacherAvailable(date, hour);
        
        if (!available) {
            return 'time-block time-block-unavailable';
        }
        return `time-block ${isBooked ? 'time-block-booked' : 'time-block-available'}`;
    };

    // 添加工具提示内容生成函数
    const getTimeSlotTooltip = (date: Date, hour: number) => {
        const { appointments: slotAppointments } = getTimeSlotInfo(date, hour);
        
        if (slotAppointments.length > 0) {
            return `已预约 (${slotAppointments.length}个):
            ${slotAppointments.map(apt => 
                `${apt.startTime}-${apt.endTime}`
            ).join('\n')}`;
        }
        return `${formatDate(date)}, ${formatTime(hour)}-${formatTime(hour + 1)}`;
    };

    const formatTime = (hour: number) => {
        return `${hour.toString().padStart(2, '0')}:00`;
    };

    // 更新点击处理函数
    const handleTimeBlockClick = (dayIndex: number, hour: number) => {
        const date = getNextSevenDays()[dayIndex];
        if (!isTeacherAvailable(date, hour)) {
            return; // 如果时间段不可用，直接返回
        }
        setSelectedSlot({
            isOpen: true,
            dayIndex,
            hour,
        });
    };

    return (
        <div className="appointment-container">
            <h2>预约时间</h2>
            <div className="timeline-container">
                {getNextSevenDays().map((date, dayIndex) => (
                    <div key={dayIndex} className="day-row">
                        <span className="day-label">{formatDate(date)}</span>
                        <div>
                            {[...Array(24)].map((_, hour) => {
                                return (
                                    <div
                                        key={hour}
                                        className={getTimeLineClass(date, hour)}
                                        data-tooltip={getTimeSlotTooltip(date, hour)}
                                        onClick={() => handleTimeBlockClick(dayIndex, hour)}
                                    >
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
            
            <AppointmentTimeSlot
                isOpen={selectedSlot.isOpen}
                onClose={() => setSelectedSlot(prev => ({ ...prev, isOpen: false }))}
                hour={selectedSlot.hour}
                date={getNextSevenDays()[selectedSlot.dayIndex]}
                existingAppointments={getTimeSlotInfo(
                    getNextSevenDays()[selectedSlot.dayIndex],
                    selectedSlot.hour
                ).appointments}
                isBooked={getTimeSlotInfo(
                    getNextSevenDays()[selectedSlot.dayIndex],
                    selectedSlot.hour
                ).isBooked}
                teacherId={teacherId}
            />
        </div>
    );
};

export default AppointmentTime;