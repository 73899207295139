import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { RootState } from "../store";
import { useAppSelector } from '../store/hooks';
import { UserType } from '../types/user';
import api from "../utils/ApiClient";
import './TeacherHome.css';

const TeacherHome = () => {
  const navigate = useNavigate();
  const { userType } = useAppSelector(state => state.user);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [createStatus, setCreateStatus] = useState<'unstarted' | 'starting' | 'success' | 'error'>('unstarted');
  const [cameraStatus, setCameraStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
  const [audioStatus, setAudioStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
  const { showConfirmDialogAwait } = useAwaitConfirmDialog();
  const [appointmentCount, setAppointmentCount] = useState<number>(0);
  const { userId } = useSelector((state: RootState) => state.user);

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    fetchAppointmentsCount();
  }, []);

  const fetchAppointmentsCount = async () => {
    try {
      const response = await api.get(`/appointments/student/booking/count`, {
        params: {
          teacherId: userId,
        }
      });
      setAppointmentCount(response.data);
    } catch (error: any) {
      alert(error.response?.data || 'Failed query appointments count');
    }
  }

  const createRoom = () => {
    setCreateStatus('starting');
    delay(2000).then(() => {
      if (UserType.TEACHER !== userType) {
        showConfirmDialogAwait('Prompt', 'you are not a teacher, you cannot create room');
        return;
      }
      const roomId = uuidv4();
      navigate(`/prep`, {
        state: {
          roomId: roomId,
        }
      });
    });
  };

  const setAppointments = () => {
    navigate('/teacherAppointmentSet');
  };

  const viewAppointments = () => {
    navigate('/teacherAppointmentView');
  };

  const testCamera = async () => {
    try {
      setCameraStatus('testing');
      console.log('开始请求摄像头权限...');
      console.log('检查 navigator.mediaDevices:', navigator.mediaDevices);
      
      // 1. 首先检查是否支持 getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.log('mediaDevices 状态:', {
          hasMediaDevices: !!navigator.mediaDevices,
          hasGetUserMedia: !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
        });
        throw new Error('browser does not support getUserMedia API');
      }

      // 2. 先获取可用的媒体设备列表
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      console.log('可用的视频设备:', videoDevices.map(device => ({
        deviceId: device.deviceId,
        label: device.label,
        kind: device.kind,
        groupId: device.groupId
      })));

      if (videoDevices.length === 0) {
        throw new Error('no camera device detected');
      }

      // 3. 如果存在之前的流，先停止它
      if (stream) {
        stream.getTracks().forEach(track => {
          console.log('停止已存在的轨道:', track.label);
          track.stop();
        });
        setStream(null);
      }

      // 4. 尝试获取摄像头权限
      console.log('准备调用 getUserMedia...');
      
      // 先尝试最简单的配置
      try {
        console.log('尝试最简单配置...');
        const simpleStream = await navigator.mediaDevices.getUserMedia({ 
          video: true 
        }).catch(error => {
          console.log('getUserMedia 简单配置失败:', error);
          throw error;
        });
        
        console.log('简单配置成功!');
        alert('test camera success');
        setCameraStatus('success');
        // Stop all tracks after successful test
        simpleStream.getTracks().forEach(track => track.stop());
        return simpleStream;
      } catch (simpleError) {
        console.log('简单配置失败，错误详情:', simpleError);
        
        // 如果简单配置失败，尝试指定设备
        console.log('尝试指定设备配置...');
        const constraints = {
          video: {
            deviceId: { exact: videoDevices[0].deviceId }
          }
        };
        
        try {
          const deviceStream = await navigator.mediaDevices.getUserMedia(constraints);
          console.log('指定设备配置成功!');
          setCameraStatus('success');
          // Stop all tracks after successful test
          deviceStream.getTracks().forEach(track => track.stop());
          return deviceStream;
        } catch (deviceError) {
          console.log('指定设备配置失败:', deviceError);
          setCameraStatus('error');
          throw deviceError;
        }
      }

    } catch (error) {
      console.error('摄像头访问失败，详细错误:', error);
      
      if (error instanceof DOMException) {
        switch(error.name) {
          case 'NotAllowedError':
            alert('user denied camera access. please check if the browser address bar shows camera permission request, or check the camera permission in the system settings.');
            break;
          case 'NotFoundError':
            alert('no camera device detected. please confirm that the camera is correctly connected.');
            break;
          case 'NotReadableError':
            alert('camera may be occupied by other applications. please try the following steps:\n1. close other applications that may use the camera (such as Teams, Zoom, Skype, etc.)\n2. refresh the page and try again\n3. restart the browser\n4. check the camera status in the device manager');
            break;
          case 'SecurityError':
            alert('camera access is blocked by security policy. please confirm that you are accessing in HTTPS or localhost environment.');
            break;
          default:
            alert(`camera error: ${error.name} - ${error.message}`);
        }
      } else {
        alert(`camera access failed: ${error instanceof Error ? error.message : 'unknown error'}`);
      }
      setCameraStatus('error');
    }
  };

  const testAudio = async () => {
    try {
      setAudioStatus('testing');
      console.log('开始请求音频权限...');
      console.log('检查 navigator.mediaDevices:', navigator.mediaDevices);

      // 1. 首先检查是否支持 getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.log('mediaDevices 状态:', {
          hasMediaDevices: !!navigator.mediaDevices,
          hasGetUserMedia: !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
        });
        throw new Error('browser does not support getUserMedia API');
      }

      // 2. 先获取可用的媒体设备列表
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      console.log('可用的音频设备:', audioDevices.map(device => ({
        deviceId: device.deviceId,
        label: device.label,
        kind: device.kind,
        groupId: device.groupId
      })));

      if (audioDevices.length === 0) {
        throw new Error('no audio device detected');
      }

      // 3. 如果存在之前的流，先停止它
      if (stream) {
        stream.getTracks().forEach(track => {
          console.log('停止已存在的轨道:', track.label);
          track.stop();
        });
        setStream(null);
      }

      // 4. 尝试获取摄像头权限
      console.log('准备调用 getUserMedia...');

      // 先尝试最简单的配置
      try {
        console.log('尝试最简单配置...');
        const simpleStream = await navigator.mediaDevices.getUserMedia({
          audio: true
        }).catch(error => {
          console.log('getUserMedia 简单配置失败:', error);
          throw error;
        });

        console.log('简单配置成功!');
        alert('test audio success');
        setAudioStatus('success');
        // Stop all tracks after successful test
        simpleStream.getTracks().forEach(track => track.stop());
        return simpleStream;
      } catch (simpleError) {
        console.log('简单配置失败，错误详情:', simpleError);

        // 如果简单配置失败，尝试指定设备
        console.log('尝试指定设备配置...');
        const constraints = {
          audio: {
            deviceId: { exact: audioDevices[0].deviceId }
          }
        };

        try {
          const deviceStream = await navigator.mediaDevices.getUserMedia(constraints);
          console.log('指定设备配置成功!');
          setAudioStatus('success');
          // Stop all tracks after successful test
          deviceStream.getTracks().forEach(track => track.stop());
          return deviceStream;
        } catch (deviceError) {
          console.log('指定设备配置失败:', deviceError);
          setAudioStatus('error');
          throw deviceError;
        }
      }

    } catch (error) {
      console.error('音频访问失败，详细错误:', error);

      if (error instanceof DOMException) {
        switch (error.name) {
          case 'NotAllowedError':
            alert('user denied audio access. please check if the browser address bar shows audio permission request, or check the audio permission in the system settings.');
            break;
          case 'NotFoundError':
            alert('no audio device detected. please confirm that the audio is correctly connected.');
            break;
          case 'NotReadableError':
            alert('audio may be occupied by other applications. please try the following steps:\n1. close other applications that may use the audio (such as Teams, Zoom, Skype, etc.)\n2. refresh the page and try again\n3. restart the browser\n4. check the audio status in the device manager');
            break;
          case 'SecurityError':
            alert('audio access is blocked by security policy. please confirm that you are accessing in HTTPS or localhost environment.');
            break;
          default:
            alert(`audio error: ${error.name} - ${error.message}`);
        }
      } else {
        alert(`audio access failed: ${error instanceof Error ? error.message : 'unknown error'}`);
      }
      setAudioStatus('error');
    }
  };

  return (
    <>
      <div className="home-container">
        <div className="action-container">
          <div className="button-group">
            <button 
              onClick={createRoom} 
              className={`action-btn ${createStatus !== 'unstarted' ? `status-${createStatus}` : ''}`}
            >
              create new room
            </button>
            <button onClick={setAppointments} className="action-btn">
              set appointments
            </button>
            <button onClick={viewAppointments} className="action-btn">
              view appointments
              <span className="appointment-count">{appointmentCount}</span>
            </button>
            <button 
              onClick={testCamera} 
              className={`action-btn ${cameraStatus !== 'untested' ? `status-${cameraStatus}` : ''}`}
            >
              test camera
            </button>
            <button 
              onClick={testAudio} 
              className={`action-btn ${audioStatus !== 'untested' ? `status-${audioStatus}` : ''}`}
            >
              test audio
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherHome; 