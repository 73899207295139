import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ButtonConfig, setCustomDialogAwait } from '../store/slices/uiSlice';
import './ConfirmDialog.css';

const CustomDialogAwait = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isOpen, title, message, buttons, resolvePromise } = useAppSelector(
        state => state.ui.customDialogAwait
    );

    if (!isOpen) return null;

    const handleClick = async (button: ButtonConfig) => {
        // 先执行自定义处理函数
        if (button.handler) {
            await button.handler();
        }
        // 然后返回按钮值并关闭对话框
        resolvePromise?.(button.value);
        dispatch(setCustomDialogAwait({ 
            isOpen: false, 
            resolvePromise: undefined,
            buttons: [] 
        }));
    };

    return (
        <div className="confirm-dialog-overlay">
            <div className="confirm-dialog">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="confirm-dialog-buttons">
                    {buttons?.map((button: ButtonConfig, index: number) => (
                        <button
                            key={index}
                            className={button.className}
                            onClick={() => handleClick(button)}
                        >
                            {t(button.text)}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomDialogAwait; 