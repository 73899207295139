import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import api from "../utils/ApiClient";
import './TeacherAppointmentView.css';

interface PageResponse<T> {
    records: T[];        // 当前页数据列表
    total: number;       // 总记录数
    size: number;        // 每页显示条数
    current: number;     // 当前页码
    pages: number;       // 总页数
}

export interface Appointment {
    id: string;
    studentId: string;
    studentFirstName: string;
    studentLastName: string;
    teacherId: string;
    date: string;
    startTime: string;
    endTime: string;
    status: string;
}

const TeacherAppointmentView = () => {
    const { t } = useTranslation();
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [pageSize] = useState(10);
    const { userId } = useSelector((state: RootState) => state.user);
    const observerTarget = useRef<HTMLDivElement>(null);

    const fetchAppointments = async (page: number) => {
        if (loading || !hasMore) return;
        
        setLoading(true);
        try {
            const response = await api.get<PageResponse<Appointment>>(`/appointments/student`, {
                params: {
                    teacherId: userId,
                    page: page,
                    pageSize: pageSize
                }
            });
            
            const newAppointments = response.data.records || [];
            if (page === 1) {
                setAppointments(newAppointments);
            } else {
                setAppointments(prev => [...prev, ...newAppointments]);
            }
            
            setHasMore(newAppointments.length === pageSize);
        } catch (error: any) {
            console.error('Failed to fetch appointments:', error);
            alert(error.response?.data || 'Failed to fetch appointments');
        } finally {
            setLoading(false);
        }
    };

    const loadMore = useCallback(() => {
        if (!loading && hasMore) {
            setCurrentPage(prev => prev + 1);
        }
    }, [loading, hasMore]);

    useEffect(() => {
        fetchAppointments(currentPage);
    }, [currentPage]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    loadMore();
                }
            },
            { threshold: 0.1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => observer.disconnect();
    }, [loadMore]);

    const handleAppointmentClick = async (appointment: Appointment) => {
        if (appointment.status === 'ST01') { 
            try {
                const response = await api.post<Appointment>(`/appointments/confirm/${appointment.id}`);
                setAppointments(prevAppointments =>
                    prevAppointments.map(apt =>
                        apt.id === response.data.id
                            ? { ...apt, status: response.data.status } // Assuming ST02 is the confirmed status
                            : apt
                    )
                );
            } catch (error: any) {
                alert(error.response.data || 'Appointment confirm failed');
            }
        }
    };

    const getStatusClassName = (status: string) => {
        switch (status) {
            case 'ST01':
                return 'status-booking';
            case 'ST02':
                return 'status-confirmed';
            case 'ST03':
                return 'status-cancelled';
            default:
                return '';
        }
    };

    return (
        <div className="appointment-container">
            <div className="appointments-grid">
                {appointments.map((appointment) => (
                    <div 
                        key={appointment.id} 
                        className={`appointment-card ${appointment.status === 'ST01' ? 'clickable' : ''}`}
                        onClick={() => handleAppointmentClick(appointment)}
                    >
                        <div className="appointment-info-group">
                            <p className="appointment-info">
                                <strong>ID</strong>
                                {appointment.id}
                            </p>
                            <p className="appointment-info">
                                <strong>Date</strong>
                                {appointment.date}
                            </p>
                            <p className="appointment-info">
                                <strong>Time</strong>
                                {appointment.startTime} - {appointment.endTime}
                            </p>
                            <p className="appointment-info">
                                <strong>Student Name</strong>
                                {appointment.studentFirstName} {appointment.studentLastName}
                            </p>
                        </div>
                        <p className={`appointment-status ${getStatusClassName(appointment.status)}`}>
                            {t(`appointmentStatusDist.${appointment.status}`)}
                        </p>
                    </div>
                ))}
            </div>
            
            {/* Loading indicator and observer target */}
            <div ref={observerTarget} className="loading-container">
                {loading && <div className="loading-spinner">{t('loading')}...</div>}
                {!hasMore && appointments.length > 0 && (
                    <div className="no-more-data">{t('noMoreData')}</div>
                )}
            </div>
        </div>
    );
};

export default TeacherAppointmentView;