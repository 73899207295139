import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setConfirmDialog } from '../store/slices/uiSlice';
import { cleanupRemoteStream, handleAnswer, handleIceCandidate, handleOffer } from '../store/slices/webrtcSlice';
import { popLastMessage } from '../store/slices/websocketSlice';
import { useChatMassageStore } from './ChatWindow';

// 创建一个全局状态存储
interface SocketStore {
    listeners: Map<string, Function[]>;
    subscribe: (event: string, callback: Function) => void;
    unsubscribe: (event: string, callback: Function) => void;
    publish: (event: string, data?: any) => void;
}

export const useSocketStore = create<SocketStore>((set, get) => ({
    listeners: new Map(),
    subscribe: (event, callback) => {
        const { listeners } = get();
        const eventListeners = listeners.get(event) || [];
        listeners.set(event, [...eventListeners, callback]);
        set({ listeners });
    },
    unsubscribe: (event, callback) => {
        const { listeners } = get();
        const eventListeners = listeners.get(event) || [];
        listeners.set(event, eventListeners.filter(cb => cb !== callback));
        set({ listeners });
    },
    publish: (event, data) => {
        const { listeners } = get();
        const eventListeners = listeners.get(event) || [];
        eventListeners.forEach(callback => callback(data));
    },
}));

const WebSocketMessageHandler = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { messages: wsMessages } = useAppSelector(state => state.websocket);
    const { roomId } = useAppSelector(state => state.room);
    const { userId } = useAppSelector(state => state.user);
    const { addMessage } = useChatMassageStore();
    
    useEffect(() => {
        if (wsMessages.length === 0) return;
        
        const lastMessage = wsMessages[wsMessages.length - 1];
        if (!lastMessage) return;

        // 移除最后一个消息
        dispatch(popLastMessage());

        if (!roomId || !userId) return;

        switch (lastMessage.type) {
            case 'offer':
                dispatch(handleOffer({
                    roomId: roomId || '',
                    userId: userId || '',
                    offer: lastMessage.payload.offer
                }));
                break;
            case 'answer':
                dispatch(handleAnswer({
                    roomId: roomId || '',
                    userId: userId || '',
                    answer: lastMessage.payload.answer
                }));
                break;
            case 'ice-candidate':
                console.log('ice-candidate received roomId:', roomId, 'userId:', userId);
                dispatch(handleIceCandidate({
                    roomId: roomId || '',
                    userId: userId || '',
                    candidate: lastMessage.payload.candidate
                }));
                break;
            case 'user-join-room':
                dispatch(setConfirmDialog({
                    isOpen: true,
                    title: (t as any)('confirm'),
                    message: lastMessage.payload.text,
                    onConfirm: () => {},
                    onCancel: () => {}
                }));
                useSocketStore.getState().publish('joinRoom');
                break;
            case 'force-leave-room':
                console.log('force-leave-room received roomId:', roomId, 'userId:', userId);
                useSocketStore.getState().publish('forceLeave', lastMessage.payload.text);
                break;
            case 'user-leave-room':
                handleUserLeaveRoom(lastMessage);
                break;
            case 'chat':
                if (lastMessage.payload.userId !== userId) {
                    handleChatMessage(lastMessage);
                }
                break;
            case 'file':
                if (lastMessage.payload.userId !== userId) {
                    handleFileMessage(lastMessage);
                }
                break;
        }
    }, [wsMessages]);

    // Handle user leave room
    const handleUserLeaveRoom = (data: any) => {
        addMessage({
            self: false,
            firstName: data.payload.firstName,
            lastName: data.payload.lastName,
            text: data.payload.text,
            timestamp: data.timestamp
        });
        dispatch(cleanupRemoteStream({
            roomId: roomId || '',
            userId: userId || ''
        }));
    };

    // Handle chat messages
    const handleChatMessage = (message: any) => {
        addMessage({
            type: 'chat',
            self: false,
            userId: message.payload.userId,
            firstName: message.payload.firstName,
            lastName: message.payload.lastName,
            text: message.payload.text,
            timestamp: message.timestamp
        });
    };

    const handleFileMessage = (message: any) => {
        addMessage({
            type: 'file',
            self: false,
            userId: message.payload.userId,
            firstName: message.payload.firstName,
            lastName: message.payload.lastName,
            fileName: message.payload.fileName,
            fileUrl: message.payload.fileUrl,
            fileSize: message.payload.fileSize,
            fileType: message.payload.fileType,
            timestamp: message.timestamp
        });
    };

    return null;
};

export default WebSocketMessageHandler; 