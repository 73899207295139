import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReferralCodeInput.module.css';

interface ReferralCodeInputProps {
  onConfirm: (code: string) => void;
  onCancel: () => void;
}

export function ReferralCodeInput({ onConfirm, onCancel }: ReferralCodeInputProps) {
  const { t } = useTranslation();
  const [referralCode, setReferralCode] = useState(['', '', '', '']);
  
  const handleCodeChange = (index: number, value: string) => {
    if (value.length > 1) return; // 每个格子只允许输入一个字符
    
    const newCode = [...referralCode];
    newCode[index] = value;
    setReferralCode(newCode);
    
    // 自动聚焦到下一个输入框
    if (value && index < 3) {
      const nextInput = document.querySelector(`input[data-index="${index + 1}"]`) as HTMLInputElement;
      nextInput?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !referralCode[index] && index > 0) {
      // 当前格子为空且按下 Backspace 时，聚焦到上一个输入框并删除其中的字符
      const newCode = [...referralCode];
      newCode[index - 1] = '';
      setReferralCode(newCode);
      
      const prevInput = document.querySelector(`input[data-index="${index - 1}"]`) as HTMLInputElement;
      prevInput?.focus();
    }
  };

  const handlePaste = (index: number, e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text').replace(/\s/g, '');
    if (!pastedText) return;

    const newCode = [...referralCode];
    // 从当前位置开始填充粘贴的内容
    for (let i = 0; i < Math.min(4 - index, pastedText.length); i++) {
      newCode[index + i] = pastedText[i];
    }
    setReferralCode(newCode);

    // 如果还有下一个输入框，聚焦到下一个
    const nextIndex = Math.min(index + pastedText.length, 3);
    if (nextIndex < 4) {
      const nextInput = document.querySelector(`input[data-index="${nextIndex}"]`) as HTMLInputElement;
      nextInput?.focus();
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.dialog}>
        <h2 className={styles.title}>{t('输入[推荐码]获取更多赠送时长')}</h2>
        <div className={styles.codeInputGroup}>
          {[0, 1, 2, 3].map((index) => (
            <input
              key={index}
              type="text"
              value={referralCode[index]}
              onChange={(e) => handleCodeChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={(e) => handlePaste(index, e)}
              className={styles.codeInput}
              maxLength={1}
              data-index={index}
            />
          ))}
        </div>
        <div className={styles.buttonGroup}>
          <button
            onClick={onCancel}
            className={styles.cancelButton}
          >
            {t('取消')}
          </button>
          <button
            onClick={() => onConfirm(referralCode.join(''))}
            className={styles.confirmButton}
          >
            {t('确认')}
          </button>
        </div>
      </div>
    </div>
  );
} 