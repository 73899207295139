import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            // Common
            confirm: 'Confirm',
            cancel: 'Cancel',
            undo: 'Undo',
            clear: 'Clear',
            save: 'Save',
            close: 'Close',
            sendBtn: 'Send',
            "userTypeDist": {
                "UT01": "admin",
                "UT02": "teacher",
                "UT03": "student"
            },
            "settleStatusDist": {
                "ST01": "pending",
                "ST02": "finished",
                "ST03": "canceled",
                "ST04": "failed"
            },
            "appointmentStatusDist": {
                "ST01": "booking",
                "ST02": "confirmed",
                "ST03": "cancelled"
            },
            refreshFailed: 'Refresh failed',
            serverNotAvailable: 'Server not available, please try again later',
            prompt: 'Prompt',

            // Login page
            login: 'Login',
            username: 'Username',
            password: 'Password',
            loginButton: 'Login',
            registerNow: 'No account? Register now',

            // Register page
            register: 'Register',
            firstName: 'First Name',
            lastName: 'Last Name',
            confirmPassword: 'Confirm Password',
            referralCode: 'Referral Code (Optional)',
            registerButton: 'Register',
            haveAccount: 'Already have an account? Login now',
            confirmRegister: 'Are you sure you want to register as "{{userType}}"?',
            teacher: 'teacher',
            student: 'student',

            // Navbar
            logout: 'Logout',
            logoutConfirm: 'logout confirm',
            leaveRoomConfirm: 'Are you sure you want to leave the room?',

            // Validation messages
            fillAllFields: 'Please fill in all fields',
            passwordMismatch: 'Passwords do not match',
            loginFailed: 'Login failed',
            registerFailed: 'Registration failed',
            serverNoResponse: 'Server not responding, please try again later',
            requestFailed: 'Request failed, please try again later',

            // Captcha
            clickDirection: 'Please click the {direction} direction button',
            validationFailed: 'Validation failed, please try again',

            // UserProfile page
            editProfile: 'Edit Profile',
            recharge: 'Recharge',
            rechargeInfo: 'Recharge Info',
            settlement: 'Settlement',
            edit: 'Edit',
            remainingTime: 'Remaining Time',
            unsettledTime: 'Unsettled Time',
            name: 'Name',
            userType: 'User Type',
            email: 'Email',
            uploadAvatarFail: 'Upload avatar failed',
            updateProfileFail: 'Update profile failed',
            getProfileFail: 'Get profile failed',
            minutes: 'minutes',
            changeAvatar: 'Change Avatar',
            changePassword: 'Change Password',
            passwordTooShort: 'Password must be at least 6 characters long',
            passwordsDoNotMatch: 'Passwords do not match',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmNewPassword: 'Confirm New Password',
            changePasswordError: 'Change password failed',
            briefIntro: 'Brief Intro',
            recAccountNo: 'Receive Account No',

            // Room page
            connectionLost: 'Connection lost, please return to home page',
            closeCamera: 'Close Camera',
            openCamera: 'Open Camera',
            closeMicrophone: 'Close Microphone',
            openMicrophone: 'Open Microphone',
            closeChat: 'Close Chat',
            openChat: 'Open Chat',
            stopShare: 'Stop Share',
            shareScreen: 'Share Screen',
            exitRoom: 'Exit Room',
            bookManagement: 'Book Management',

            // api
            loginExpired: 'Login expired, please login again',

            // BookManagement page
            title: 'Book',
            downloadFailed: 'Download failed, please try again later',
            downloading: 'Downloading',
            download: 'Download',

            // ConnectionPrep page
            roomTitle: 'Room Connection Status',
            wsConnected: 'Signal Server Connection Successful',
            wsConnecting: 'Connecting to Signal Server...',
            wsDisconnected: 'Signal Server Connection Disconnected',
            wsFailed: 'Signal Server Connection Failed',
            wsNew: 'Signal Server Connection in Progress',
            webrtcConnected: 'Media Service Connection Successful',
            webrtcConnecting: 'Establishing Media Connection...',
            webrtcDisconnected: 'Media Service Connection Disconnected',
            webrtcFailed: 'Media Service Connection Failed',
            webrtcNew: 'Media Service Connection in Progress',
            userParamError: 'User Parameter Error',
            connectionParamError: 'Connection Parameter Error',
            socketConnectNoReady: 'socket connect no ready',
            mediaNoReady: 'media no ready, please later again',

            // DrawingCanvas page
            pen: 'Pen',
            eraser: 'Eraser',
            text: 'Text',
        }
    },
    zh: {
        translation: {
            // Common
            confirm: '确认',
            cancel: '取消',
            undo: '撤销',
            clear: '清除',
            save: '保存',
            close: '关闭',
            sendBtn: '发送',
            "userTypeDist": {
                "UT01": "管理员",
                "UT02": "教师",
                "UT03": "学生"
            },
            "settleStatusDist": {
                "ST01": "待确认",
                "ST02": "已确认",
                "ST03": "已取消",
                "ST04": "失败"
            },
            refreshFailed: '刷新失败',
            serverNotAvailable: '服务器不可用，请稍后再试',
            prompt: '提示',

            // Login page
            login: '登录',
            username: '用户名',
            password: '密码',
            loginButton: '登录',
            registerNow: '没有账号？立即注册',

            // Register page
            register: '注册',
            firstName: '名',
            lastName: '姓',
            confirmPassword: '确认密码',
            referralCode: '推荐码（选填）',
            registerButton: '注册',
            haveAccount: '已有账号？立即登录',
            confirmRegister: '您确认要注册为"{{userType}}"吗？',
            teacher: '老师',
            student: '学生',

            // Navbar
            logout: '退出登录',
            logoutConfirm: '退出确认',
            leaveRoomConfirm: '确定要离开房间吗？',

            // Validation messages
            fillAllFields: '请填写所有字段',
            passwordMismatch: '两次输入的密码不一致',
            loginFailed: '登录失败',
            registerSuccess: '注册成功',
            registerFailed: '注册失败',
            serverNoResponse: '服务器无响应，请稍后重试',
            requestFailed: '请求失败，请稍后重试',

            // Captcha
            clickDirection: '请点击{direction}方向的按钮',
            validationFailed: '验证失败，请重试',

            // UserProfile page
            editProfile: '编辑资料',
            recharge: '充值',
            rechargeInfo: '充值信息',
            settlement: '结算',
            edit: '编辑',
            remainingTime: '剩余时间',
            unsettledTime: '未结算课时',
            name: '姓名',
            userType: '用户类型',
            email: '邮箱',
            uploadAvatarFail: '上传头像失败',
            updateProfileFail: '更新用户信息失败',
            getProfileFail: '获取用户信息失败',
            minutes: '分钟',
            changeAvatar: '更换头像',
            changePassword: '修改密码',
            passwordTooShort: '密码长度至少为6位',
            passwordsDoNotMatch: '两次输入的密码不一致',
            currentPassword: '当前密码',
            newPassword: '新密码',
            confirmNewPassword: '确认新密码',
            changePasswordError: '修改密码失败',
            briefIntro: '简介',
            recAccountNo: '收款账户',

            // Room page
            connectionLost: '连接丢失，请返回首页',
            closeCamera: '关闭摄像头',
            openCamera: '开启摄像头',
            closeMicrophone: '关闭麦克风',
            openMicrophone: '开启麦克风',
            closeChat: '收起聊天',
            openChat: '展开聊天',
            stopShare: '停止共享',
            shareScreen: '共享屏幕',
            exitRoom: '退出房间',
            bookManagement: '书籍管理',

            // api
            loginExpired: '登录已过期，请重新登录',

            // BookManagement page
            title: '书籍',
            downloadFailed: '下载失败，请稍后重试',
            downloading: '下载中',
            download: '下载',

            // ConnectionPrep page
            roomTitle: '房间连接状态',
            wsConnected: '信令服务器连接成功',
            wsConnecting: '正在连接信令服务器...',
            wsDisconnected: '信令服务器连接断开',
            wsFailed: '信令服务器连接失败',
            wsNew: '信令服务器连接中',
            webrtcConnected: '媒体服务连接成功',
            webrtcConnecting: '正在建立媒体连接...',
            webrtcDisconnected: '媒体服务连接断开',
            webrtcFailed: '媒体服务连接失败',
            webrtcNew: '媒体服务连接中',
            userParamError: '用户参数异常',
            connectionParamError: '连接参数错误',
            socketConnectNoReady: 'socket连接未准备好',
            mediaNoReady: '媒体流未准备好,请稍后重试',

            // DrawingCanvas page
            pen: '画笔',
            eraser: '橡皮擦',
            text: '文字',
        }
    }
} as const;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;