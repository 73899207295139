import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useAppSelector } from '../store/hooks';
import api from '../utils/ApiClient';
import styles from './Recharge.module.css';

interface SystemConfig {
    id: string;
    configKey: string;
    configParam1: string;
    configValue: string;
    configDesc: string;
    configType: string;
    module: string;
    isSystem: boolean;
    status: string;
}

interface BonusRate {
    amount: number;
    bonusRate: number;
}

const Recharge = () => {
    const navigate = useNavigate();
    const { userId } = useAppSelector(state => state.user);
    const [error, setError] = useState('');
    const [amount, setAmount] = useState<number | string>('');
    const [amounts, setAmounts] = useState<number[]>([]);
    const [bonusTime, setBonusTime] = useState<number>(0);
    const [bonusRates, setBonusRates] = useState<BonusRate[]>([]);
    const { showConfirmDialogAwait } = useAwaitConfirmDialog();

    const calculateBonusTime = (value: number): number => {        
        const bonusRate = bonusRates.find(item => item.amount === value);
        if (bonusRate) {
            return value + value * bonusRate.bonusRate;
        }
        return value;
    };

    useEffect(() => {
        api.get('/recharge/bonus-rates')
            .then(res => {
                console.log(res);
                setAmounts(res.data.map((item: SystemConfig) => Number(item.configParam1)));
                setBonusRates(res.data.map((item: SystemConfig) => ({
                    amount: Number(item.configParam1),
                    bonusRate: Number(item.configValue)
                })));
            })
            .catch(err => console.error('Failed to fetch bonus rates:', err));
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setAmount(value);
            setBonusTime(calculateBonusTime(Number(value)));
        }
    };

    const handlePredefinedAmount = (value: number) => {
        setAmount(value.toString());
        setBonusTime(calculateBonusTime(value));
        setError('');
    };

    const handleRecharge = () => {
        if (!amount || parseFloat(amount as string) <= 0) {
            setError('请输入有效的充值金额');
            return;
        }

        setError('');
        api.post('/recharge/create', {
            userId: userId,
            amount: amount
        }).then(res => {
            const newWindow = window.open();
            if (newWindow) {
                newWindow.document.open();
                newWindow.document.write(res.data);
                newWindow.document.close();
            }
        }).catch(err => {
            console.log(err);
            showConfirmDialogAwait('提示', err.response.data);
            return;
        });
    };

    return (
        <>
            <Navbar />
            <div className={styles.container}>
                <div className={styles.card}>
                    <h2>充值</h2>
                    {error && <div className={styles.errorMessage}>{error}</div>}

                    <div className={styles.amounts}>
                        {amounts.map((value) => (
                            <button
                                key={value}
                                className={`${styles.amountButton} ${amount === value.toString() ? styles.selected : ''}`}
                                onClick={() => handlePredefinedAmount(value)}
                            >
                                {value}元
                            </button>
                        ))}
                    </div>

                    <div className={styles.formGroup}>
                        <label>自定义金额（元）</label>
                        <input
                            type="text"
                            placeholder="请输入充值金额"
                            value={amount}
                            onChange={handleInputChange}
                        />
                    </div>

                    {amount && (
                        <div className={styles.bonusTime}>
                            充值后可用时长：{bonusTime.toFixed(1)}分钟
                        </div>
                    )}

                    <div className={styles.buttonGroup}>
                        <button className={styles.button} onClick={handleRecharge}>
                            确认充值
                        </button>
                        <button className={styles.button} onClick={() => navigate('/profile')}>
                            取消
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Recharge;
