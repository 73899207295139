import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import TeacherIntro from '../components/TeacherIntro';
import config from '../config/config';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useAppSelector } from '../store/hooks';
import { UserType } from '../types/user';
import api from '../utils/ApiClient';
import './StudentHome.css';

interface Teacher {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  online: boolean;
  avatar: string;
  roomId: string;
}

const StudentHome = () => {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher | null>(null);
  const [showTeacherIntro, setShowTeacherIntro] = useState(false);
  const { userType, userId } = useAppSelector(state => state.user);
  const { showConfirmDialogAwait, showCustomDialogAwait } = useAwaitConfirmDialog();

  const joinRoom = async (roomId: string, teacherId: string) => {
    const teacher = teachers.find(t => t.id === teacherId);
    if (!teacher) return;

    if (!roomId) {
      setSelectedTeacher(teacher);
      setShowTeacherIntro(true);
      return;
    }

    const result = await showCustomDialogAwait('选择操作', '', [
        {
          text: '预约',
          value: 'book',
          className: 'confirm-dialog-buttons'
        },
        {
          text: '上课',
          value: 'class',
          className: 'confirm-dialog-buttons'
        }
      ]);

    if (result === 'book') {
      setSelectedTeacher(teacher);
      setShowTeacherIntro(true);
      return;
    }

    if (!userId) {
      showConfirmDialogAwait('提示', '请先登录');
      return;
    }

    if (UserType.STUDENT !== userType) {
      showConfirmDialogAwait('提示', '您不是学生，无法加入房间');
      return;
    }

    try {
      const response = await api.post('/room/checkJoin', {
        roomId: roomId,
        userId: userId
      });
      
      navigate(`/studentPrep`, {
        state: {
          roomId: roomId,
          teacherId: teacherId
        }
      });
    } catch (error: any) {
      showConfirmDialogAwait('提示', error.response.data);
    }
  }

  const handleCloseIntro = () => {
    setShowTeacherIntro(false);
    setSelectedTeacher(null);
  };

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        console.log('Fetching teachers from URL:', `${config.API_URL}/teachers`);
        const response = await api.get<Teacher[]>('/teachers');
        console.log('Teachers API Response:', response);
        setTeachers(response.data);
      } catch (error) {
        console.error('Failed to fetch teachers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeachers();
  }, []);

  useEffect(() => {
    if (loading) return;

    const retryInterval = 4000; // 4秒后重试
    let retryTimeout: NodeJS.Timeout;

    const connectSSE = () => {
      console.log('Connecting to SSE URL:', config.SSE_URL);
      const eventSource = new EventSource(`${config.SSE_URL}`);

      eventSource.onmessage = (event) => {
        console.log("Received SSE message:", event.data);
        updateTeacherList(JSON.parse(event.data) as Teacher);
      };

      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSource.close();
        console.log(`Attempting to reconnect...`);
        retryTimeout = setTimeout(connectSSE, retryInterval);
      };

      return eventSource;
    };

    const eventSource = connectSSE();

    return () => {
      console.log('Cleaning up SSE connection');
      clearTimeout(retryTimeout);
      eventSource.close();
    };
  }, [loading]);

  const updateTeacherList = (data: Teacher) => {
    setTeachers(prevTeachers => {
      console.log("prevTeachers:", prevTeachers);
      const updatedTeachers = prevTeachers.map(teacher => {
        if (teacher.id === data.id) {
          return {
            ...teacher,
            roomId: data.roomId,
            online: data.online
          };
        }
        return teacher;
      });

      if (data.online) {
        const onlineTeacherIndex = updatedTeachers.findIndex(
          teacher => teacher.id === data.id
        );
        if (onlineTeacherIndex > -1) {
          const [onlineTeacher] = updatedTeachers.splice(onlineTeacherIndex, 1);
          updatedTeachers.unshift(onlineTeacher);
        }
      }

      console.log("updatedTeachers:", updatedTeachers);
      return updatedTeachers;
    });
  }

  if (loading) {
    return <div className="loading">加载中...</div>;
  }

  return (
    <>
      <div className="teacher-list">
        <div className="teacher-grid">
          {teachers.map((teacher) => (
            <div
              key={teacher.id}
              className="teacher-card"
              onClick={() => joinRoom(teacher.roomId, teacher.id)}
            >
              {teacher.online && <div className="online-indicator" />}
              <div className="avatar-container">
                <img 
                  src={teacher.avatar || "https://api.dicebear.com/7.x/thumbs/svg?seed=6&radius=50"} 
                  alt={teacher.firstName + teacher.lastName} 
                  className="avatar" 
                />
              </div>
              <div className="teacher-name">{teacher.firstName} {teacher.lastName}</div>
            </div>
          ))}
        </div>
      </div>
      
      {showTeacherIntro && selectedTeacher && (
        <TeacherIntro
          teacher={selectedTeacher}
          onClose={handleCloseIntro}
        />
      )}
      <Footer />
    </>
  );
};

export default StudentHome; 