import React, { useEffect, useRef } from 'react';

interface StreamWindowProps {
    localStream: MediaStream | null;
    remoteStream: MediaStream | null;
    onClose?: () => void;
}

const StreamWindow: React.FC<StreamWindowProps> = ({ 
    localStream, 
    remoteStream,
    onClose 
}) => {
    const localVideoRef = useRef<HTMLVideoElement>(null);
    const remoteVideoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (localVideoRef.current && localStream) {
            localVideoRef.current.srcObject = localStream;
            localVideoRef.current.muted = true;
        }
        if (remoteVideoRef.current && remoteStream) {
            remoteVideoRef.current.srcObject = remoteStream;
            remoteVideoRef.current.muted = true;
        }
    }, [localStream, remoteStream]);

    const containerStyle: React.CSSProperties = {
        padding: '30px',
        display: 'flex',
        gap: '20px',
        background: '#f8f9fa'
    };

    const wrapperStyle: React.CSSProperties = {
        position: 'relative',
        width: '240px',
        height: '180px',
        borderRadius: '12px',
        overflow: 'hidden',
        background: '#000',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
    };

    const videoStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block'
    };

    const labelStyle: React.CSSProperties = {
        position: 'absolute',
        bottom: '12px',
        left: '50%',
        transform: 'translateX(-50%)',
        color: 'white',
        background: 'rgba(0, 0, 0, 0.6)',
        padding: '6px 12px',
        borderRadius: '20px',
        fontSize: '14px'
    };

    return (
        <div style={containerStyle}>
            <div style={wrapperStyle}>
                <video
                    style={videoStyle}
                    ref={localVideoRef}
                    autoPlay
                    playsInline
                    muted
                />
                <div style={labelStyle}>local</div>
            </div>
            <div style={wrapperStyle}>
                <video
                    style={videoStyle}
                    ref={remoteVideoRef}
                    autoPlay
                    playsInline
                    muted
                />
                <div style={labelStyle}>remote</div>
            </div>
        </div>
    );
};

export default StreamWindow; 