import api from '../utils/ApiClient';
import axios, { CancelTokenSource } from 'axios';

export const downloadBook = async (
    bookId: string, 
    handle: FileSystemFileHandle,
    onProgress?: (progress: number) => void,
    cancelToken?: CancelTokenSource
): Promise<void> => {
    let writable: FileSystemWritableFileStream | null = null;
    let firstChunkReceived = false;
    let fileSize = 0;  // 用于存储文件大小
    
    try {
        const response = await api.get(`/books/${bookId}/download`, {
            responseType: 'blob',
            cancelToken: cancelToken?.token,
            onDownloadProgress: (progressEvent) => {
                console.log('Download progress:', progressEvent);

                // 尝试从响应头获取文件大小
                if (!fileSize && progressEvent.event instanceof ProgressEvent) {
                    const xhr = progressEvent.event.target as XMLHttpRequest;
                    const fileSizeHeader = xhr.getResponseHeader('x-file-size');
                    if (fileSizeHeader) {
                        fileSize = parseInt(fileSizeHeader, 10);
                        console.log('Got file size from header:', fileSize);
                    }
                }

                // 第一个数据块时设置为1%
                if (!firstChunkReceived) {
                    firstChunkReceived = true;
                    onProgress?.(1);
                    return;
                }

                // 计算进度
                let currentProgress;
                if (fileSize > 0) {
                    // 使用实际文件大小计算进度
                    currentProgress = Math.min(
                        Math.round((progressEvent.loaded / fileSize) * 100),
                        98
                    );
                } else {
                    // 回退到估算进度
                    currentProgress = Math.min(
                        Math.round((progressEvent.loaded / (progressEvent.total || progressEvent.loaded)) * 100),
                        98
                    );
                }

                console.log('Loaded bytes:', progressEvent.loaded);
                console.log('File size:', fileSize);
                console.log('Current progress:', currentProgress);

                onProgress?.(currentProgress);
            },
            headers: {
                'Accept': 'application/octet-stream',
                'Cache-Control': 'no-cache'
            },
            timeout: 0
        });

        if (!response.data || !(response.data instanceof Blob)) {
            throw new Error('Invalid response data');
        }

        const blob = new Blob([response.data], {
            type: response.headers['content-type'] || 'application/octet-stream'
        });

        console.log('Final blob size:', blob.size);

        // 确保显示 100% 进度
        onProgress?.(100);

        writable = await handle.createWritable();
        await writable.write(blob);

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('下载已取消');
            throw new Error('DOWNLOAD_CANCELLED');
        }
        console.error('下载失败:', error);
        throw error;
    } finally {
        // 确保在任何情况下都关闭文件流
        if (writable) {
            try {
                await writable.close();
            } catch (closeError) {
                console.error('关闭文件流失败:', closeError);
            }
        }
    }
};


export const downloadBookWithUrl = async (
    bookId: string,
    fileName: string,
    onProgress?: (progress: number) => void,
    cancelToken?: CancelTokenSource
): Promise<void> => {
    let writable: FileSystemWritableFileStream | null = null;
    let firstChunkReceived = false;
    let fileSize = 0;  // 用于存储文件大小

    try {
        const response = await api.get(`/books/${bookId}/download`, {
            responseType: 'blob',
            cancelToken: cancelToken?.token,
            onDownloadProgress: (progressEvent) => {
                console.log('Download progress:', progressEvent);

                // 尝试从响应头获取文件大小
                if (!fileSize && progressEvent.event instanceof ProgressEvent) {
                    const xhr = progressEvent.event.target as XMLHttpRequest;
                    const fileSizeHeader = xhr.getResponseHeader('x-file-size');
                    if (fileSizeHeader) {
                        fileSize = parseInt(fileSizeHeader, 10);
                        console.log('Got file size from header:', fileSize);
                    }
                }

                // 第一个数据块时设置为1%
                if (!firstChunkReceived) {
                    firstChunkReceived = true;
                    onProgress?.(1);
                    return;
                }

                // 计算进度
                let currentProgress;
                if (fileSize > 0) {
                    // 使用实际文件大小计算进度
                    currentProgress = Math.min(
                        Math.round((progressEvent.loaded / fileSize) * 100),
                        98
                    );
                } else {
                    // 回退到估算进度
                    currentProgress = Math.min(
                        Math.round((progressEvent.loaded / (progressEvent.total || progressEvent.loaded)) * 100),
                        98
                    );
                }

                console.log('Loaded bytes:', progressEvent.loaded);
                console.log('File size:', fileSize);
                console.log('Current progress:', currentProgress);

                onProgress?.(currentProgress);
            },
            headers: {
                'Accept': 'application/octet-stream',
                'Cache-Control': 'no-cache'
            },
            timeout: 0
        });

        if (!response.data || !(response.data instanceof Blob)) {
            throw new Error('Invalid response data');
        }

        const blob = new Blob([response.data], {
            type: response.headers['content-type'] || 'application/octet-stream'
        });

        console.log('Final blob size:', blob.size);

        // 创建下载链接
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        // 下载完成
        onProgress?.(100);

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('下载已取消');
            throw new Error('DOWNLOAD_CANCELLED');
        }
        console.error('下载失败:', error);
        throw error;
    }
};

export const downloadFile = async (
    roomId: string,
    fileName: string,
    handle: FileSystemFileHandle,
    onProgress?: (progress: number) => void,
    cancelToken?: CancelTokenSource
): Promise<void> => {
    let writable: FileSystemWritableFileStream | null = null;
    let firstChunkReceived = false;
    let fileSize = 0;  // 用于存储文件大小
    
    try {
        const response = await api.get(
            `/room/${roomId}/download/${encodeURIComponent(fileName)}`,
            {
                responseType: 'blob',
                cancelToken: cancelToken?.token,
                onDownloadProgress: (progressEvent) => {
                    console.log('Download progress:', progressEvent);
                    
                    // 尝试从响应头获取文件大小
                    if (!fileSize && progressEvent.event instanceof ProgressEvent) {
                        const xhr = progressEvent.event.target as XMLHttpRequest;
                        const fileSizeHeader = xhr.getResponseHeader('x-file-size');
                        if (fileSizeHeader) {
                            fileSize = parseInt(fileSizeHeader, 10);
                            console.log('Got file size from header:', fileSize);
                        }
                    }
                    
                    // 第一个数据块时设置为1%
                    if (!firstChunkReceived) {
                        firstChunkReceived = true;
                        onProgress?.(1);
                        return;
                    }
                    
                    // 计算进度
                    let currentProgress;
                    if (fileSize > 0) {
                        // 使用实际文件大小计算进度
                        currentProgress = Math.min(
                            Math.round((progressEvent.loaded / fileSize) * 100),
                            98
                        );
                    } else {
                        // 回退到估算进度
                        currentProgress = Math.min(
                            Math.round((progressEvent.loaded / (progressEvent.total || progressEvent.loaded)) * 100),
                            98
                        );
                    }
                    
                    console.log('Loaded bytes:', progressEvent.loaded);
                    console.log('File size:', fileSize);
                    console.log('Current progress:', currentProgress);
                    
                    onProgress?.(currentProgress);
                },
                headers: {
                    'Accept': 'application/octet-stream',
                    'Cache-Control': 'no-cache'
                },
                timeout: 0
            }
        );

        if (!response.data || !(response.data instanceof Blob)) {
            throw new Error('Invalid response data');
        }

        const blob = new Blob([response.data], {
            type: response.headers['content-type'] || 'application/octet-stream'
        });

        console.log('Final blob size:', blob.size);
        
        // 确保显示 100% 进度
        onProgress?.(100);

        writable = await handle.createWritable();
        await writable.write(blob);

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('下载已取消');
            throw new Error('DOWNLOAD_CANCELLED');
        }
        console.error('下载失败:', error);
        throw error;
    } finally {
        if (writable) {
            try {
                await writable.close();
            } catch (closeError) {
                console.error('关闭文件流失败:', closeError);
            }
        }
    }
};

export const downloadFileWithUrl = async (
    roomId: string,
    fileName: string,
    onProgress?: (progress: number) => void,
    cancelToken?: CancelTokenSource
): Promise<void> => {
    try {
        let firstChunkReceived = false;
        let fileSize = 0;  // 用于存储文件大小
        let lastProgress = 0;  // 上次进度，避免频繁更新

        // 下载文件
        const response = await api.get(
            `/room/${roomId}/download/${encodeURIComponent(fileName)}`,
            {
                responseType: 'blob',
                cancelToken: cancelToken?.token,
                onDownloadProgress: (progressEvent) => {
                    console.log('Download progress:', progressEvent);

                    // 尝试从响应头获取文件大小
                    if (!fileSize && progressEvent.event instanceof ProgressEvent) {
                        const xhr = progressEvent.event.target as XMLHttpRequest;
                        const fileSizeHeader = xhr.getResponseHeader('x-file-size');
                        if (fileSizeHeader) {
                            fileSize = parseInt(fileSizeHeader, 10);
                            console.log('Got file size from header:', fileSize);
                        }
                    }

                    // 第一个数据块时设置为1%
                    if (!firstChunkReceived) {
                        firstChunkReceived = true;
                        onProgress?.(1);
                        return;
                    }

                    // 计算进度
                    let currentProgress;
                    if (fileSize > 0) {
                        // 使用实际文件大小计算进度
                        currentProgress = Math.min(
                            Math.round((progressEvent.loaded / fileSize) * 100),
                            98
                        );
                    } else {
                        // 回退到估算进度
                        currentProgress = Math.min(
                            Math.round((progressEvent.loaded / (progressEvent.total || progressEvent.loaded)) * 100),
                            98
                        );
                    }

                    // 只有进度发生变化时才更新，避免频繁更新
                    if (currentProgress !== lastProgress) {
                        lastProgress = currentProgress;
                        console.log('Loaded bytes:', progressEvent.loaded);
                        console.log('File size:', fileSize);
                        console.log('Current progress:', currentProgress);
                        onProgress?.(currentProgress);
                    }
                },
                headers: {
                    'Accept': 'application/octet-stream',
                    'Cache-Control': 'no-cache'
                },
                timeout: 120000  // 设置超时为 2 分钟
            }
        );

        // 创建下载链接
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        // 下载完成
        onProgress?.(100);

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('下载已取消');
            throw new Error('DOWNLOAD_CANCELLED');
        }
        console.error('下载出错:', error);
        throw error;
    }
};