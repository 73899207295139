import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SellerNavbar from '../components/SellerNavbar';
import { RootState } from '../store';
import api from '../utils/ApiClient';
import './SellerHome.css';

interface SellerInfo {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  status: string;
  referralCode: string;
  sellerAccount?: {
    id: string;
    accountNo: string;
    totalAmount: number;
    settledAmount: number;
    unsettledAmount: number;
    settleRate: number;
  };
}

const SellerHome = () => {
  const [sellerInfo, setSellerInfo] = useState<SellerInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state: RootState) => state.user.userId); 
  const navigate = useNavigate();
  const [showSettlementModal, setShowSettlementModal] = useState(false);
  const [settlementAmount, setSettlementAmount] = useState('');
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [newAccountNo, setNewAccountNo] = useState('');

  useEffect(() => {
    fetchSellerInfo();
  }, []);

  // 更新账户号
  const handleAccountNoUpdate = async () => {
    try {
      if (!newAccountNo.trim()) {
        alert('请输入账户号');
        return;
      }

      await api.put(`/sellers/account/${sellerInfo?.id}`, {
        accountNo: newAccountNo
      });

      alert('账户号更新成功');
      setShowAccountModal(false);
      setNewAccountNo('');
      // Refresh seller info
      fetchSellerInfo();
    } catch (error: any) {
      alert(error.response?.data || 'Update failed');
    }
  };

  // 提交结算
  const handleSettlement = async () => {
    try {
      const amount = parseFloat(settlementAmount);
      if (isNaN(amount) || amount <= 0) {
        alert('请输入有效的金额');
        return;
      }
      
      if (amount > sellerInfo?.sellerAccount?.unsettledAmount!) {
        alert('结算金额不能超过未结算金额');
        return;
      }

      await api.post(`/sellers/settleApply`, {
        sellerId: userId,
        settledAmount: amount
      });

      alert('结算提交成功');
      setShowSettlementModal(false);
      setSettlementAmount('');
      // Refresh seller info to get updated amounts
      fetchSellerInfo();
    } catch (error: any) {
      alert(error.response?.data || '结算失败');
    }
  };

  // 获取卖家信息
  const fetchSellerInfo = async () => {
    try {
      const response = await api.get(`/sellers/${userId}`);
      setSellerInfo(response.data);
    } catch (error: any) {
      alert(error.response.data || '查询异常');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!sellerInfo) return <div>No seller information found</div>;

  return (
    <div className="seller-home">      
      <SellerNavbar />
      <div className="seller-profile">
        <h2>个人信息</h2>
          <div className="info-grid">
            <div>
              <p>姓名: {sellerInfo.firstName} {sellerInfo.lastName}</p>
              <p>用户名: {sellerInfo.username}</p>
              <p>推荐码: {sellerInfo.referralCode}</p>
              <p>状态: {sellerInfo.status === 'ST01' ? '启用' : '禁用'}</p>
            </div>
          </div>
        </div>

        {sellerInfo.sellerAccount && (
          <div className="seller-account">
            <h2>账户信息</h2>
            <div className="info-grid">
              <div>
                <p className="info-row">
                  账号: {sellerInfo.sellerAccount.accountNo}
                  <button 
                    className="action-button" 
                    onClick={() => setShowAccountModal(true)}
                  >
                    更新
                  </button>
                </p>
                <p>总金额: ¥{sellerInfo.sellerAccount.totalAmount.toFixed(2)}</p>
                <p>已结算金额: ¥{sellerInfo.sellerAccount.settledAmount.toFixed(2)}</p>
                <p className="info-row">
                  未结算金额: ¥{sellerInfo.sellerAccount.unsettledAmount.toFixed(2)}
                  <button 
                    className="action-button" 
                    onClick={() => setShowSettlementModal(true)}
                  >
                    结算
                  </button>
                </p>
                <p>结算率: {sellerInfo.sellerAccount.settleRate * 100}%</p>
              </div>
            </div>
          </div>
        )}

        {showSettlementModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Enter Settlement Amount</h3>
              <div className="modal-body">
                <input
                  type="number"
                  value={settlementAmount}
                  onChange={(e) => setSettlementAmount(e.target.value)}
                  placeholder="Enter amount"
                  max={sellerInfo?.sellerAccount?.unsettledAmount}
                  step="0.01"
                />
                <div className="modal-actions">
                  <button 
                    className="action-button" 
                    onClick={() => setShowSettlementModal(false)}
                  >
                    Cancel
                  </button>
                  <button 
                    className="action-button primary" 
                    onClick={handleSettlement}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showAccountModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Update Account Number</h3>
              <div className="modal-body">
                <input
                  type="text"
                  value={newAccountNo}
                  onChange={(e) => setNewAccountNo(e.target.value)}
                  placeholder="Enter new account number"
                />
                <div className="modal-actions">
                  <button 
                    className="action-button" 
                    onClick={() => setShowAccountModal(false)}
                  >
                    Cancel
                  </button>
                  <button 
                    className="action-button primary" 
                    onClick={handleAccountNoUpdate}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
  );
};

export default SellerHome;