import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReferralCodeInput } from '../components/ReferralCodeInput';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useAppDispatch } from '../store/hooks';
import { setUser } from '../store/slices/userSlice';
import api from '../utils/ApiClient';
import styles from './LoginScanRedirect.module.css';

function LoginScanRedirect() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCode = new URLSearchParams(window.location.search).get('auth_code');
  const dispatch = useAppDispatch();
  const { showConfirmDialogAwait } = useAwaitConfirmDialog();
  const [showReferralDialog, setShowReferralDialog] = useState(false);
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    handleLogin();
  }, []);

  const handleLogin = async () => {
    if (!authCode) {
      showConfirmDialogAwait(t('prompt'), t('登录异常'));
      navigate('/login');
      return;
    }
    try {
      const response = await api.get(`/login/${authCode}`);

      const { token, id, userType, lastName, firstName, avatar, accessToken } = response.data;
      if (id) {
        dispatch(setUser({
          token,
          userId: id,
          userType,
          lastName,
          firstName,
          avatar,
        }));
        navigate('/');
        return;
      }
      // 如果没有 id，显示推荐码输入对话框
      setAccessToken(accessToken);
      setShowReferralDialog(true);
    } catch (error: any) {
      showConfirmDialogAwait(t('prompt'), error.response.data || t('loginFailed'));
      navigate('/login');
    }
  };

  const handleRegister = async (referralCode: string) => {
    try {
      const response = await api.post(`/register/${accessToken}`, {
        referralCode,
      });
      console.log('response.data', response.data);
      const { token, id, userType, lastName, firstName, avatar } = response.data;
      if (id) {
        dispatch(setUser({
          token,
          userId: id,
          userType,
          lastName,
          firstName,
          avatar,
        }));
        navigate('/');
        return;
      }
    } catch (error: any) {
      await showConfirmDialogAwait(t('prompt'), error.response.data || t('注册失败'));
      navigate('/login');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.spinner}></div>
        <h1 className={styles.title}>{t('请稍后...')}</h1>
      </div>
      {showReferralDialog && (
        <ReferralCodeInput
          onConfirm={(code: string) => {
            setShowReferralDialog(false);
            handleRegister(code);
          }}
          onCancel={() => {
            setShowReferralDialog(false);
            handleRegister('');
          }}
        />
      )}
    </div>
  );
}

export default LoginScanRedirect;
