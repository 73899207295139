import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ButtonConfig {
  text: string;
  value: any;
  className?: string;
  handler?: () => void | Promise<void>;
}

interface ConfirmDialogState {
  isOpen: boolean;
  title?: string;
  message?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface ConfirmDialogAwaitState {
  isOpen: boolean;
  title?: string;
  message?: string;
  resolvePromise?: (value: boolean) => void;
}

interface CustomDialogAwaitState {
  isOpen: boolean;
  title?: string;
  message?: string;
  buttons?: ButtonConfig[];
  resolvePromise?: (value: any) => void;
}

interface UiState {
  confirmDialog: ConfirmDialogState;
  confirmDialogAwait: ConfirmDialogAwaitState;
  customDialogAwait: CustomDialogAwaitState;
  // ... 其他 UI 状态
}

const initialState: UiState = {
  confirmDialog: {
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
  },
  confirmDialogAwait: {
    isOpen: false,
    title: '',
    message: '',
    resolvePromise: () => {},
  },
  customDialogAwait: {
    isOpen: false,
    buttons: []
  },
  // ... 其他初始状态
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setConfirmDialog: (state, action: PayloadAction<Partial<ConfirmDialogState>>) => {
      state.confirmDialog = {
        ...state.confirmDialog,
        ...action.payload,
      };
    },
    setConfirmDialogAwait: (state, action: PayloadAction<Partial<ConfirmDialogAwaitState>>) => {
      state.confirmDialogAwait = {
        ...state.confirmDialogAwait,
        ...action.payload,
      };
    },
    setCustomDialogAwait: (state, action: PayloadAction<Partial<CustomDialogAwaitState>>) => {
      state.customDialogAwait = { ...state.customDialogAwait, ...action.payload };
    }
  },
});

export const { setConfirmDialog, setConfirmDialogAwait, setCustomDialogAwait } = uiSlice.actions;
export default uiSlice.reducer; 