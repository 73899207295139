import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadBook, downloadBookWithUrl } from '../services/downloadService';
import api from '../utils/ApiClient';
import './BookManagement.css';
import axios, { CancelTokenSource } from 'axios';

interface Book {
    id: string;
    title: string;
    author: string;
    imgUrl: string;
    // Add more book properties as needed
}

interface BookManagementProps {
    isOpen: boolean;
    onClose: () => void;
}

const BookManagement: React.FC<BookManagementProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [books, setBooks] = useState<Book[]>([]);
    const [loading, setLoading] = useState(true);
    const [downloadingBooks, setDownloadingBooks] = useState<Set<string>>(new Set());
    const [downloadProgress, setDownloadProgress] = useState<Record<string, number>>({});
    const [downloadCancelTokens, setDownloadCancelTokens] = useState<Record<string, CancelTokenSource>>({});

    useEffect(() => {
        if (isOpen) {
            // TODO: Replace with actual API call
            fetchBooks();
        }
    }, [isOpen]);

    const fetchBooks = async () => {
        try {

            const response = await api.get('/books');
            const books = response.data;
            setBooks(books);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching books:', error);
            setLoading(false);
        }
    };

    const handleDownload = async (bookId: string, fileName: string) => {
        try {
            // 如果已经在下载，则取消当前下载
            if (downloadProgress[bookId] !== undefined) {
                downloadCancelTokens[bookId]?.cancel('用户取消下载');
                setDownloadProgress(prev => ({
                    ...prev,
                    [bookId]: 0
                }));
                
                setTimeout(() => {
                    setDownloadProgress(prev => {
                        const newProgress = { ...prev };
                        delete newProgress[bookId];
                        return newProgress;
                    });
                    setDownloadCancelTokens(prev => {
                        const newTokens = { ...prev };
                        delete newTokens[bookId];
                        return newTokens;
                    });
                }, 300);
                return;
            }

            // const handle = await window.showSaveFilePicker({
            //     suggestedName: fileName,
            //     types: [{
            //         description: 'PDF Document',
            //         accept: {'application/pdf': ['.pdf']}
            //     }]
            // });

            // 创建新的取消令牌
            const cancelTokenSource = axios.CancelToken.source();
            setDownloadCancelTokens(prev => ({
                ...prev,
                [bookId]: cancelTokenSource
            }));

            setDownloadingBooks(prev => new Set(prev).add(bookId));
            setDownloadProgress(prev => ({ ...prev, [bookId]: 0 }));
            
            await downloadBookWithUrl(
                bookId, 
                fileName, 
                (progress: number) => {
                    setDownloadProgress(prev => ({ ...prev, [bookId]: progress }));
                }, 
                cancelTokenSource
            );
            
        } catch (error) {
            if (error instanceof Error && error.message !== 'DOWNLOAD_CANCELLED') {
                alert(t('downloadFailed'));
            }
        } finally {
            setDownloadingBooks(prev => {
                const newSet = new Set(prev);
                newSet.delete(bookId);
                return newSet;
            });
            
            setTimeout(() => {
                setDownloadProgress(prev => {
                    const newProgress = { ...prev };
                    delete newProgress[bookId];
                    return newProgress;
                });
                setDownloadCancelTokens(prev => {
                    const newTokens = { ...prev };
                    delete newTokens[bookId];
                    return newTokens;
                });
            }, 300);
        }
    };

    const handleCancelDownload = (bookId: string) => {
        const cancelToken = downloadCancelTokens[bookId];
        if (cancelToken) {
            cancelToken.cancel('用户取消下载');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="book-management-modal">
            <div className="book-management-content">
                <div className="book-management-header">
                    <h2>{t('title')}</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                
                <div className="book-list">
                    {loading ? (
                        <div className="loading">加载中...</div>
                    ) : (
                        books.map(book => (
                            <div key={book.id} className="book-item">
                                <img 
                                    src={'https://via.placeholder.com/150'}
                                    alt={book.title} 
                                    className="book-cover"
                                />
                                <div className="book-info">
                                    <h3>{book.title}</h3>
                                    <p>{book.author}</p>
                                    {downloadingBooks.has(book.id) ? (
                                        <div className="download-controls">
                                            <div className="download-progress">
                                                <div 
                                                    className="progress-bar"
                                                    style={{ width: `${downloadProgress[book.id] || 0}%` }}
                                                />
                                            </div>
                                            <div className="progress-text">
                                                {`${downloadProgress[book.id] || 0}%`}
                                            </div>
                                            <button 
                                                className="cancel-button"
                                                onClick={() => handleDownload(book.id, book.title)}
                                            >
                                                {t('cancel')}
                                            </button>
                                        </div>
                                    ) : (
                                        <button 
                                            className="download-button"
                                            onClick={() => handleDownload(book.id, book.title)}
                                        >
                                            {t('download')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookManagement; 