import { useAppDispatch } from '../store/hooks';
import { setConfirmDialogAwait, setCustomDialogAwait, ButtonConfig } from '../store/slices/uiSlice';

export const useAwaitConfirmDialog = () => {
    const dispatch = useAppDispatch();

    const showConfirmDialogAwait = (title: string, message: string): Promise<boolean> => {
        return new Promise((resolve) => {
            dispatch(setConfirmDialogAwait({
                isOpen: true,
                title,
                message,
                resolvePromise: resolve
            }));
        });
    };

    const showCustomDialogAwait = (title: string, message: string, buttons: ButtonConfig[]): Promise<any> => {
        return new Promise((resolve) => {
            dispatch(setCustomDialogAwait({
                isOpen: true,
                title,
                message,
                buttons,
                resolvePromise: resolve
            }));
        });
    };

    return { showConfirmDialogAwait, showCustomDialogAwait };
};