import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useAppSelector } from '../store/hooks';
import api from '../utils/ApiClient';
import styles from './UserProfile.module.css';

interface UserProfile {
  email: string;
  username: string;
  lastName: string;
  firstName: string;
  avatar: string;
}

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useAppSelector(state => state.user);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<UserProfile | null>(null);
  const [error, setError] = useState('');
  const [isEditRecAccountModalVisible, setIsEditRecAccountModalVisible] = useState(false);
  const [editRecAccountNo, setEditRecAccountNo] = useState('');
  const { showConfirmDialogAwait } = useAwaitConfirmDialog();

  useEffect(() => {
    fetchUserProfile();
  }, [userId]);

  const fetchUserProfile = async () => {
    try {
      const response = await api.get(`/sellers`);
      setProfile(response.data);
      setFormData(response.data);
      setError('');
    } catch (error) {
      setError((t as any)('getProfileFail'));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    // e.preventDefault();
    // if (!formData) return;

    // try {
    //   const response = await api.put(`/users`, formData);
    //   if (response.data === false) {
    //     setError((t as any)('updateProfileFail'));
    //     return;
    //   }

    //   setProfile(formData);
    //   setIsEditing(false);
    //   setError('');

    //   dispatch(updateUser({
    //     email: formData.email,
    //     username: formData.username,
    //     firstName: formData.firstName,
    //     lastName: formData.lastName,
    //   }));
    // } catch (error) {
    //   setError((t as any)('updateProfileFail'));
    // }
  };

  const handleEditRecAccount = async () => {
    try {
      const response = await api.put(`/teachers/recAccountNo/${userId}`, {
        recAccountNo: editRecAccountNo
      });
      if (response.data) {
        fetchUserProfile();
        setIsEditRecAccountModalVisible(false);
        setEditRecAccountNo('');
      }
    } catch (error: any) {
      showConfirmDialogAwait(t('prompt'), error.response?.data || 'edit rec account failed');
    }
  };

  if (!profile) {
    return <div className="loading">加载中...</div>;
  }

  return (
    <>
      <div className={styles['profile-container']}>
        <div className={styles['profile-card']}>
          {error && <div className={styles['error-message']}>{error}</div>}

          <div className={styles['avatar-section']}>
            <img
              src={profile.avatar || 'https://api.dicebear.com/7.x/thumbs/svg?seed=6&radius=50'}
              alt="用户头像"
              className={styles['profile-avatar']}
            />
            <div className={styles['avatar-upload']}>
              {/* <label htmlFor="avatar-input" className={styles['avatar-upload-button']}>
                {(t as any)('changeAvatar')}
              </label>
              <input
                id="avatar-input"
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                style={{ display: 'none' }}
              /> */}
            </div>
          </div>

          {isEditing ? (
            <form onSubmit={handleSubmit} className={styles['profile-form']}>
              <div className={styles['form-group']}>
                <label>{(t as any)('email')}</label>
                <input
                  type="email"
                  name="email"
                  value={formData?.email || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles['form-group']}>
                <label>{(t as any)('username')}</label>
                <input
                  type="text"
                  name="username"
                  value={formData?.username || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles['form-group']}>
                <label>{(t as any)('firstName')}</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData?.firstName || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles['form-group']}>
                <label>{(t as any)('lastName')}</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData?.lastName || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles['button-group']}>
                <button
                  type="button"
                  className={styles['cancel-button']}
                  onClick={() => {
                    setIsEditing(false);
                    setFormData(profile);
                  }}
                >
                  {(t as any)('cancel')}
                </button>
                <button
                  type="submit"
                  className={styles['edit-button']}
                  onClick={handleSubmit}
                >
                  {(t as any)('save')}
                </button>
              </div>
            </form>
          ) : (
            <div className={styles['profile-info']}>
              <div className={styles['info-group']}>
                <label>{(t as any)('email')}</label>
                <span>{profile.email}</span>
              </div>

              <div className={styles['info-group']}>
                <label>{(t as any)('username')}</label>
                <span>{profile.username}</span>
              </div>

              <div className={styles['info-group']}>
                <label>{(t as any)('name')}</label>
                <span>{`${profile.firstName} ${profile.lastName}`}</span>
              </div>

              <div className={styles['button-group']}>
                <button
                  className={styles['edit-button']}
                  onClick={() => navigate('/sellerChangePassword')}
                >
                  {(t as any)('changePassword')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile; 