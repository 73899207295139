import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { updateAvatar, updateUser } from '../store/slices/userSlice';
import { UserType } from '../types/user';
import api from '../utils/ApiClient';
import styles from './UserProfile.module.css';

interface UserProfile {
  email: string;
  username: string;
  lastName: string;
  firstName: string;
  avatar: string;
  userType: string;
  balanceTime: string;
  freeTime: string;
  unsettledTime: string;
  recAccountNo: string;
  briefIntro: string;
}

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useAppSelector(state => state.user);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<UserProfile | null>(null);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const [isSettlementModalVisible, setIsSettlementModalVisible] = useState(false);
  const [settledTime, setSettledTime] = useState('');
  const [isEditRecAccountModalVisible, setIsEditRecAccountModalVisible] = useState(false);
  const [editRecAccountNo, setEditRecAccountNo] = useState('');
  const { showConfirmDialogAwait } = useAwaitConfirmDialog();

  useEffect(() => {
    fetchUserProfile();
  }, [userId]);

  const fetchUserProfile = async () => {
    try {
      const response = await api.get(`/users`);
      setProfile(response.data);
      setFormData(response.data);
      setError('');
    } catch (error) {
      setError((t as any)('getProfileFail'));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData) return;

    try {
      const response = await api.put(`/users`, formData);
      if (response.data === false) {
        setError((t as any)('updateProfileFail'));
        return;
      }

      setProfile(formData);
      setIsEditing(false);
      setError('');

      dispatch(updateUser({
        email: formData.email,
        username: formData.username,
        firstName: formData.firstName,
        lastName: formData.lastName,
        briefIntro: formData.briefIntro,
      }));
    } catch (error) {
      setError((t as any)('updateProfileFail'));
    }
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    if (!e.target.files || !e.target.files[0]) return;

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const response = await api.put(`/users/${userId}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setProfile(prev => prev ? { ...prev, avatar: response.data } : null);
      setFormData(prev => prev ? { ...prev, avatar: response.data } : null);

      dispatch(updateAvatar(response.data));
    } catch (error) {
      setError((t as any)('uploadAvatarFail'));
    }
  };

  const handleSettlement = async () => {
    try {
      console.log(settledTime);
      const response = await api.post(`/teachers/settleApply/${userId}`, {
        settledTime: parseFloat(settledTime)
      });
      if (response.data) {
        fetchUserProfile();
        setIsSettlementModalVisible(false);
        setSettledTime('');
      }
    } catch (error: any) {
      showConfirmDialogAwait(t('prompt'), error.response?.data || 'settlement failed');
    }
  };

  const handleEditRecAccount = async () => {
    try {
      const response = await api.put(`/teachers/recAccountNo/${userId}`, {
        recAccountNo: editRecAccountNo
      });
      if (response.data) {
        fetchUserProfile();
        setIsEditRecAccountModalVisible(false);
        setEditRecAccountNo('');
      }
    } catch (error: any) {
      showConfirmDialogAwait(t('prompt'), error.response?.data || 'edit rec account failed');
    }
  };

  if (!profile) {
    return <div className="loading">加载中...</div>;
  }

  return (
    <>
      <Navbar />
      <div className={styles['profile-container']}>
        <div className={styles['profile-card']}>
          {error && <div className={styles['error-message']}>{error}</div>}

          <div className={styles['avatar-section']}>
            <img
              src={profile.avatar || 'https://api.dicebear.com/7.x/thumbs/svg?seed=6&radius=50'}
              alt="用户头像"
              className={styles['profile-avatar']}
            />
            <div className={styles['avatar-upload']}>
              <label htmlFor="avatar-input" className={styles['avatar-upload-button']}>
                {(t as any)('changeAvatar')}
              </label>
              <input
                id="avatar-input"
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                style={{ display: 'none' }}
              />
            </div>
          </div>

          {isEditing ? (
            <form onSubmit={handleSubmit} className={styles['profile-form']}>
              <div className={styles['form-group']}>
                <label>{(t as any)('email')}</label>
                <input
                  type="email"
                  name="email"
                  value={formData?.email || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles['form-group']}>
                <label>{(t as any)('username')}</label>
                <input
                  type="text"
                  name="username"
                  value={formData?.username || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles['form-group']}>
                <label>{(t as any)('firstName')}</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData?.firstName || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles['form-group']}>
                <label>{(t as any)('lastName')}</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData?.lastName || ''}
                  onChange={handleInputChange}
                />
              </div>

              {isEditing && UserType.TEACHER === formData?.userType && (
                <div className={styles['form-group']}>
                  <label>{(t as any)('briefIntro')}</label>
                  <input
                    type="text"
                    name="briefIntro"
                    value={formData?.briefIntro || ''}
                    onChange={handleInputChange}
                    maxLength={150}
                  />
                </div>
              )}

              <div className={styles['button-group']}>
                <button
                  type="button"
                  className={styles['cancel-button']}
                  onClick={() => {
                    setIsEditing(false);
                    setFormData(profile);
                  }}
                >
                  {(t as any)('cancel')}
                </button>
                <button
                  type="submit"
                  className={styles['edit-button']}
                  onClick={handleSubmit}
                >
                  {(t as any)('save')}
                </button>
              </div>
            </form>
          ) : (
            <div className={styles['profile-info']}>
              <div className={styles['info-group']}>
                <label>{(t as any)('email')}</label>
                <span>{profile.email}</span>
              </div>

              <div className={styles['info-group']}>
                <label>{(t as any)('username')}</label>
                <span>{profile.username}</span>
              </div>

              <div className={styles['info-group']}>
                <label>{(t as any)('name')}</label>
                <span>{`${profile.firstName} ${profile.lastName}`}</span>
              </div>

              <div className={styles['info-group']}>
                <label>{(t as any)('userType')}</label>
                <span>{t(`userTypeDist.${profile.userType}`)}</span>
              </div>

              {UserType.TEACHER === profile.userType && (
                <>
                  <div className={styles['info-group']}>
                    <label>{(t as any)('unsettledTime')}</label>
                    <span>{profile.unsettledTime} {(t as any)('minutes')}</span>
                    <button
                      className={styles['action-button']}
                      onClick={() => setIsSettlementModalVisible(true)}
                    >
                      {(t as any)('settlement')}
                    </button>
                  </div>

                  <div className={styles['info-group']}>
                    <label>{(t as any)('recAccountNo')}</label>
                    <span>{profile.recAccountNo || '-'}</span>
                    <button
                      className={styles['action-button']}
                      onClick={() => setIsEditRecAccountModalVisible(true)}
                    >
                      {(t as any)('edit')}
                    </button>
                  </div>
                </>
              )}
              {UserType.STUDENT === profile.userType && (
                <div className={styles['info-group']}>
                  <label>{(t as any)('remainingTime')}</label>
                  <div className={styles['time-info']}>
                    <span>{(Number(profile.balanceTime) + Number(profile.freeTime))} {(t as any)('minutes')}</span>
                    <button
                      className={styles['recharge-button']}
                      onClick={() => navigate('/recharge')}
                    >
                      {(t as any)('recharge')}
                    </button>
                    <button
                      className={styles['recharge-button']}
                      onClick={() => navigate('/rechargeList')}
                    >
                      {(t as any)('rechargeInfo')}
                    </button>
                  </div>
                </div>
              )}
              {UserType.TEACHER === profile.userType && (
                <div className={styles['info-group']}>
                  <label>{(t as any)('briefIntro')}</label>
                  <span>{profile.briefIntro || '-'}</span>
                </div>
              )}
              <div className={styles['button-group']}>
                <button
                  className={styles['edit-button']}
                  onClick={() => setIsEditing(true)}
                >
                  {(t as any)('editProfile')}
                </button>
                <button
                  className={styles['edit-button']}
                  onClick={() => navigate('/changePassword')}
                >
                  {(t as any)('changePassword')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {isSettlementModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Enter Settlement Time</h3>
            <div className="modal-body">
              <input
                type="number"
                value={settledTime}
                onChange={(e) => setSettledTime(e.target.value)}
                placeholder="Enter time"
                max={profile?.unsettledTime}
                step="1"
              />
              <div className="modal-actions">
                <button
                  className="action-button"
                  onClick={() => setIsSettlementModalVisible(false)}
                >
                  Cancel
                </button>
                <button
                  className="action-button primary"
                  onClick={handleSettlement}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isEditRecAccountModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Enter Rec Account No</h3>
            <div className="modal-body">
              <input
                type="text"
                value={editRecAccountNo}
                onChange={(e) => setEditRecAccountNo(e.target.value)}
                placeholder="Enter rec account"
              />
              <div className="modal-actions">
                <button
                  className="action-button"
                  onClick={() => setIsEditRecAccountModalVisible(false)}
                >
                  Cancel
                </button>
                <button
                  className="action-button primary"
                  onClick={handleEditRecAccount}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile; 