import React, { useEffect, useState } from 'react';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useAppSelector } from '../store/hooks';
import { UserType } from '../types/user';
import api from '../utils/ApiClient';
import { Appointment } from './AppointmentTime';
import './AppointmentTimeSlot.css';

interface AppointmentTimeSlotProps {
    isOpen: boolean;
    onClose: () => void;
    hour: number;
    isBooked: boolean;
    date: Date;
    bookedTimeRanges?: Array<{ start: number; end: number; }>;
    existingAppointments: Appointment[];
    teacherId: string;
}

const AppointmentTimeSlot: React.FC<AppointmentTimeSlotProps> = ({ isOpen, onClose, hour, isBooked, date, existingAppointments, teacherId }) => {
    const { userId, userType } = useAppSelector(state => state.user);
    const [isDragging, setIsDragging] = useState(false);
    const [showTimeRange, setShowTimeRange] = useState(false);
    const [startMinute, setStartMinute] = useState<number | null>(null);
    const [endMinute, setEndMinute] = useState<number | null>(null);
    const [tempStart, setTempStart] = useState<number | null>(null);
    const [tempEnd, setTempEnd] = useState<number | null>(null);
    const { showConfirmDialogAwait } = useAwaitConfirmDialog();

    // 将预约数据转换为时间范围
    const bookedRanges = existingAppointments.map(apt => {
        const [startHour, startMinute] = apt.startTime.split(':').map(Number);
        const [endHour, endMinute] = apt.endTime.split(':').map(Number);
        
        // 计算相对于当前小时的分钟偏移
        const start = (startHour === hour) ? startMinute : 0;
        const end = (endHour === hour) ? endMinute : 60;
        
        return { start, end };
    });

    // 当下拉框选择改变时，更新状态条
    useEffect(() => {
        if (startMinute !== null && endMinute !== null) {
            setTempStart(startMinute);
            setTempEnd(endMinute);
        }
    }, [startMinute, endMinute]);

    const handleMouseDown = (minute: number) => {
        // 清除之前的选择
        setStartMinute(minute);
        setEndMinute(minute);
        setTempStart(minute);
        setTempEnd(minute);
        
        // 设置新的选择
        setIsDragging(true);
    };

    const handleMouseMove = (minute: number) => {
        if (isDragging && tempStart !== null) {
            setTempEnd(minute);
            setEndMinute(minute);
        }
    };

    const handleMouseUp = (minute: number) => {
        setIsDragging(false);
        // 判断滑动方向
        if (tempStart !== null && minute < tempStart) {
            // 反向滑动：先设置结束时间，再设置开始时间
            setEndMinute(tempStart);
            setStartMinute(minute);
        } else {
            // 正向滑动：先设置开始时间，再设置结束时间
            setStartMinute(tempStart);
            setEndMinute(minute + 1);
        }
    };

    // 监听值的变化
    useEffect(() => {
        console.log('Minutes changed - start:', startMinute, 'end:', endMinute);
    }, [startMinute, endMinute]);

    // useEffect(() => {
    //     document.addEventListener('mouseup', handleMouseUp);
    //     return () => document.removeEventListener('mouseup', handleMouseUp);
    // }, []);

    if (!isOpen) return null;

    // 格式化时间
    const formatTime = (hour: number, minute: number = 0) => {
        hour = minute === 60 ? hour + 1 : hour;
        minute = minute === 60 ? 0 : minute;
        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    const formatDate = (date: Date) => {
        return date.toLocaleDateString('zh-CN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'short'
        });
    };

    // 辅助函数：将日期转换为 YYYY-MM-DD 格式
    const formatDateToString = (date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleBooking = async () => {
        if (UserType.STUDENT !== userType) {
            showConfirmDialogAwait('提示', '您不是学生，无法预约');
            return;
        }

        if (!startMinute || !endMinute) {
            showConfirmDialogAwait('提示', '请先选择预约时间段');
            return;
        }

        const start = Math.min(startMinute, endMinute);
        const end = Math.max(startMinute, endMinute);

        const hasConflict = bookedRanges.some(range => 
            (start < range.end && end > range.start)
        );

        if (hasConflict) {
            showConfirmDialogAwait('提示', '所选时间段与已预约时间段重叠，请重新选择');
            return;
        }

        console.log(`预约时间: ${formatTime(hour, start)} - ${formatTime(hour, end)}`);

        // 发送预约请求
        try {
            const response = await api.post('/appointments/student/create', {
                date: formatDateToString(date),
                startTime: formatTime(hour, start),
                endTime: hour === 23 ? (end === 60 ? '00:00' : formatTime(hour, end)) : formatTime(hour, end),
                teacherId: teacherId,
                studentId: userId,
            });
            showConfirmDialogAwait('提示', '预约成功');
            onClose();
        } catch (error: any) {
            showConfirmDialogAwait('提示', "预约失败: " + error.response.data || '预约失败');
        }
    };

    const onChangeRangeStartMinute = async (val: number | null) => {
        if (val === null) {
            setStartMinute(null);
            return;
        }
        if (endMinute && val > endMinute) {
            setEndMinute(val);
        } else {
            setStartMinute(val);
        }
    }

    const onChangeRangeEndMinute = async (val: number | null) => {
        if (val === null) {
            setEndMinute(null);
            return;
        }
        if (startMinute && val < startMinute) {
            setStartMinute(val)
        } else {
            setEndMinute(val);
        }
    }

    const TimelineBar = ({ bookedTimeRanges }: { bookedTimeRanges: Array<{ start: number; end: number }> }) => {
        const getMinuteClassName = (minute: number) => {
            // 检查这一分钟时间段（minute到minute+1）是否与任何已预约时间段重叠
            const isBooked = bookedTimeRanges.some(
                range => minute < range.end && (minute + 1) > range.start
            );
            
            // 检查这一分钟时间段是否在选择范围内
            const isSelected = tempStart !== null && tempEnd !== null &&
                minute < Math.max(tempStart, tempEnd) &&
                (minute + 1) > Math.min(tempStart, tempEnd);

            return `timeline-minute ${isBooked ? 'booked' : 'available'} ${isSelected ? 'selected' : ''}`;
        };

        return (
            <div>
                <div className="timeline-bar">
                    {[...Array(60)].map((_, minute) => (
                        <div
                            key={minute}
                            className={getMinuteClassName(minute)}
                            onMouseDown={() => handleMouseDown(minute)}
                            onMouseMove={() => handleMouseMove(minute)}
                            onMouseUp={() => handleMouseUp(minute)}
                            title={`${formatTime(hour, minute)}`}
                        />
                    ))}
                </div>
                {tempStart !== null && tempEnd !== null && (
                    <div className="selection-info">
                        已选择: {Math.abs(tempEnd - tempStart)} 分钟 
                        ({formatTime(hour, Math.min(tempStart, tempEnd))} - 
                        {formatTime(hour, Math.max(tempStart, tempEnd))})
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h3>预约时间详情</h3>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    <p>日期: {formatDate(date)}</p>
                    <p>时间: {formatTime(hour)} - {formatTime(hour + 1)}</p>
                    {/* <p>状态: {isBooked ? '已预约' : '可预约'}</p> */}
                    
                    <div className="timeline-container">
                        <p className="timeline-label">时间段占用情况：</p>
                        <TimelineBar bookedTimeRanges={bookedRanges} />
                    </div>

                    <button
                        className="time-range-button"
                        onClick={() => setShowTimeRange(!showTimeRange)}
                    >
                        {showTimeRange ? '收起时间段选择' : '选择具体时间段'}
                    </button>

                    {showTimeRange && (
                        <div className="time-range-selector">
                            <div className="time-input-group">
                                <label>开始时间:</label>
                                <select
                                    value={startMinute ?? ''}
                                    onChange={(e) => {
                                        const value = e.target.value === '' ? null : Number(e.target.value);
                                        onChangeRangeStartMinute(value);
                                    }}
                                >
                                    <option value="">请选择</option>
                                    {[...Array(61)].map((_, i) => (
                                        <option key={i} value={i}>{i === 60 ? '00' : i.toString().padStart(2, '0')}分</option>
                                    ))}
                                </select>
                            </div>
                            <div className="time-input-group">
                                <label>结束时间:</label>
                                <select
                                    value={endMinute ?? ''}
                                    onChange={(e) => {
                                        const value = e.target.value === '' ? null : Number(e.target.value);
                                        onChangeRangeEndMinute(value);
                                    }}
                                >
                                    <option value="">请选择</option>
                                    {[...Array(61)].map((_, i) => (
                                        <option key={i} value={i}>{i === 60 ? '00' : i.toString().padStart(2, '0')}分</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}

                    <button
                        className="book-button"
                        onClick={handleBooking}
                    >
                        确认预约
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AppointmentTimeSlot; 