import React, { useRef, useState, useEffect } from 'react';
import { FaPen, FaEraser, FaUndo, FaSave, FaTimes, FaTrash, FaFont } from 'react-icons/fa';
import styles from './DrawingCanvas.module.css';
import { useTranslation } from 'react-i18next';

interface DrawingCanvasProps {
  isOpen: boolean;
  onClose: () => void;
}

const DrawingCanvas: React.FC<DrawingCanvasProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [tool, setTool] = useState<'pen' | 'eraser' | 'text'>('pen');
  const [color, setColor] = useState('#000000');
  const [lineWidth, setLineWidth] = useState(2);
  const [undoStack, setUndoStack] = useState<ImageData[]>([]);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [textInput, setTextInput] = useState('');
  const [textPosition, setTextPosition] = useState<{ x: number; y: number } | null>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const dpr = window.devicePixelRatio || 1;
      
      // 设置画布的实际大小
      canvas.width = 800 * dpr;
      canvas.height = 600 * dpr;
      
      // 使用CSS设置显示大小
      canvas.style.width = '800px';
      canvas.style.height = '600px';
      
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      if (ctx) {
        // 根据设备像素比缩放上下文
        ctx.scale(dpr, dpr);
        
        ctx.lineWidth = lineWidth;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.shadowBlur = 1;
        setContext(ctx);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        setUndoStack([imageData]);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (context) {
      context.strokeStyle = tool === 'eraser' ? '#ffffff' : color;
      context.lineWidth = lineWidth;
      context.shadowColor = tool === 'eraser' ? '#ffffff' : color;
    }
  }, [color, lineWidth, tool, context]);

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!context || !canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    
    if (tool === 'text') {
      setTextPosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
      return;
    }

    // Save current canvas state for undo
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    setUndoStack([...undoStack, imageData]);

    setIsDrawing(true);
    
    context.beginPath();
    context.moveTo(
      e.clientX - rect.left,
      e.clientY - rect.top
    );
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing || !context) return;
    
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    context.strokeStyle = tool === 'eraser' ? '#ffffff' : color;
    context.lineWidth = lineWidth;
    
    // 添加压力感应效果
    const speed = Math.abs(e.movementX) + Math.abs(e.movementY);
    const dynamicLineWidth = Math.max(lineWidth - (speed * 0.1), lineWidth * 0.5);
    context.lineWidth = dynamicLineWidth;
    
    context.lineTo(
      e.clientX - rect.left,
      e.clientY - rect.top
    );
    context.stroke();
  };

  const stopDrawing = () => {
    if (!context) return;
    setIsDrawing(false);
    context.closePath();
  };

  const handleUndo = () => {
    if (!context || !canvasRef.current || undoStack.length === 0) return;
    
    const previousState = undoStack[undoStack.length - 1];
    context.putImageData(previousState, 0, 0);
    setUndoStack(undoStack.slice(0, -1));
  };

  const handleClear = () => {
    if (!context || !canvasRef.current) return;
    
    // 保存当前状态用于撤销
    const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
    setUndoStack([...undoStack, imageData]);
    
    // 清除画布
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const handleSave = () => {
    if (!canvasRef.current) return;
    
    const link = document.createElement('a');
    link.download = 'drawing.png';
    link.href = canvasRef.current.toDataURL();
    link.click();
  };

  const handleTextSubmit = () => {
    if (!context || !textPosition || !textInput.trim()) return;
    
    // Save current state for undo
    const imageData = context.getImageData(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    setUndoStack([...undoStack, imageData]);

    // Temporarily disable all shadow effects for text
    context.shadowBlur = 0;
    context.shadowColor = 'transparent';
    context.imageSmoothingEnabled = false;

    // Draw text
    context.font = `${lineWidth * 8}px Arial`;
    context.fillStyle = color;
    context.fillText(textInput, textPosition.x, textPosition.y);
    
    // Restore shadow effects for other tools
    context.shadowBlur = 1;
    context.shadowColor = tool === 'eraser' ? '#ffffff' : color;
    context.imageSmoothingEnabled = true;
    
    // Reset text input state
    setTextInput('');
    setTextPosition(null);
  };

  // 修改拖动相关的处理函数
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    // 检查是否点击的是工具栏区域，且不是输入控件
    if (e.target instanceof Element && 
        e.target.closest(`.${styles['canvas-toolbar']}`) && 
        !(e.target instanceof HTMLInputElement) && 
        !(e.target instanceof HTMLButtonElement)) {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // 添加全局鼠标事件监听
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setIsDragging(false);
    };

    const handleGlobalMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;
        setPosition({
          x: newX,
          y: newY
        });
      }
    };

    if (isOpen) {
      window.addEventListener('mouseup', handleGlobalMouseUp);
      window.addEventListener('mousemove', handleGlobalMouseMove);
    }

    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
      window.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, [isOpen, isDragging, dragStart]);

  // 修改工具切换函数
  const handleToolChange = (newTool: 'pen' | 'eraser' | 'text') => {
    setTool(newTool);
    // 切换工具时清除文字输入状态
    setTextPosition(null);
    setTextInput('');
  };

  // 修改 useEffect 来处理文本输入框的焦点
  useEffect(() => {
    if (textPosition && textInputRef.current) {
      // 添加一个小延时来确保DOM已经渲染
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 0);
    }
  }, [textPosition]);

  if (!isOpen) return null;

  return (
    <div 
      className={styles['canvas-container']}
      style={{
        transform: `translate(calc(-50% + ${position.x}px), ${position.y}px)`,
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className={styles['canvas-toolbar']}>
        <button 
          className={`${styles['canvas-tool-btn']} ${tool === 'pen' ? styles.active : ''}`}
          onClick={() => handleToolChange('pen')}
        >
          <FaPen /> {t('pen')}
        </button>
        <button 
          className={`${styles['canvas-tool-btn']} ${tool === 'eraser' ? styles.active : ''}`}
          onClick={() => handleToolChange('eraser')}
        >
          <FaEraser /> {t('eraser')}
        </button>
        <button 
          className={`${styles['canvas-tool-btn']} ${tool === 'text' ? styles.active : ''}`}
          onClick={() => handleToolChange('text')}
        >
          <FaFont /> {t('text')}
        </button>
        <input
          type="color"
          className={styles['color-picker']}
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
        <input
          type="range"
          min="1"
          max="10"
          value={lineWidth}
          onChange={(e) => setLineWidth(Number(e.target.value))}
        />
        <button className={styles['canvas-tool-btn']} onClick={handleUndo}>
          <FaUndo /> {t('undo')}
        </button>
        <button className={styles['canvas-tool-btn']} onClick={handleClear}>
          <FaTrash /> {t('clear')}
        </button>
        <button className={styles['canvas-tool-btn']} onClick={handleSave}>
          <FaSave /> {t('save')}
        </button>
        <button className={styles['canvas-tool-btn']} onClick={onClose}>
          <FaTimes /> {t('close')}
        </button>
      </div>
      <div className={`${styles['canvas-area']} ${styles[tool]}`}>
        <canvas
          ref={canvasRef}
          width={800}
          height={600}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
      </div>
      {textPosition && (
        <div 
          className={styles['text-input-container']} 
          style={{ 
            left: textPosition.x + 'px', 
            top: textPosition.y + 'px' 
          }}
        >
          <input
            ref={textInputRef}
            type="text"
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTextSubmit();
              }
            }}
            autoFocus
          />
          <button onClick={handleTextSubmit}>
            {t('add')}
          </button>
        </div>
      )}
    </div>
  );
};

export default DrawingCanvas; 